import { default as OlMapOverlay } from "sccOlMapOverlay";
const olStyleStyle = require("ol/style/Style").default;
const olStyleFill = require("ol/style/Fill").default;
const olStyleStroke = require("ol/style/Stroke").default;
const _ = require("lodash");
const Geofence = require("sccGeofence").default;
const inclusiveGeofenceColor = [0, 255, 0, 0.7]; //"#00FF00";
const exclusiveGeofenceColor = [255, 0, 0, 0.7]; //"#FF0000";
const inactiveGeofenceColor = [153, 147, 147, 0.7]; //"#999393"

const olProj = require("ol/proj");
const olCollection = require("ol/Collection").default;

let selectGeofenceOptions = {
  active: false,
  inclusive: true,
};

//feature that is drawn on the map
let drawFeature = null;
let preEditStyle = null;

class GeofenceOverlay extends OlMapOverlay.OlMapOverlay {
  constructor(options) {
    super(options);
    this.moduleName = "geofence_overlay";
    this.showEndBtn = false;
    this.showDragMsg = false;
    this.floatingFeatureOnMap = false; //boolean which tracks whether there is a geofence drawing on the map that has
    //not been saved as a geofence yet
  }

  init(options) {
    super.init(options);

    this.addOverlay();
    this.addFeature({
      data: getGeofences(),
    });
  }

  selectFeature(feature, options) {
    super.selectFeature(feature, options);

    const geofenceId = feature.get("id");
    const geofence = Geofence.get(geofenceId);

    this.setSelectGeofenceOptions({
      active: geofence.active,
      inclusive: geofence.inclusive,
    });
  }

  refresh(data, removeData) {
    super.refresh(data, removeData);

    // update path style with new width
    if (data && data.width) {
      // keep this console here to test in production
      let featureId = null;
      if (!_.isObject(data)) {
        featureId = data;
      } else {
        featureId = this.getId(data);
      }
      const feature = this.getFeature(featureId);
      const newStyle = getGeofenceStyle(feature);

      feature.setStyle(newStyle);
    }
  }

  getStyle(feature) {
    super.getStyle();

    return getGeofenceStyle(feature);
  }

  getStyleGivenInclusiveActiveFlags(inclusive, active, noStroke) {
    return getStyleGivenInclusiveActiveFlags(inclusive, active, noStroke);
  }

  getSelectStyle(feature) {
    super.getSelectStyle(feature);
    // skipping the dummy feature used for extra feature for selection
    if (feature.getId()) {
      return getGeofenceStyle(feature);
    }
    return new olStyleStyle({
      stroke: new olStyleStroke({
        color: getGeofenceColor(selectGeofenceOptions),
        lineDash: [7, 7],
        width: 4,
      }),
      fill: new olStyleFill({
        color: getSelectFillColor(feature),
      }),
    });
  }

  getDrawFeature() {
    return drawFeature;
  }
  setDrawFeature(feature) {
    drawFeature = feature;
  }
  startDrawing(shape) {
    this.startDrawing(shape, null);
  }

  removeFeature(feature) {
    super.removeFeature(feature);
  }

  startDrawing(shape, callback) {
    const type = this.getShapeType(shape);
    const options = {};
    options.drawFinished = function (feature) {
      feature.setId(0);
      drawFeature = feature;
      if (callback != null) {
        callback(feature);
      }
    };
    this.OlMap.startDrawing(type, this.selectIconSource, options);
    return options;
  }

  endDrawing() {
    this.selectIconSource.clear();
    drawFeature = null;
    this.OlMap.endDrawing();
  }

  getDrawingCoordinates() {
    if (drawFeature == null) return null;

    const type = drawFeature.getGeometry().getType();
    const sphere = require("ol/sphere");

    let coords = [];
    let coordsTransformed = [];
    let width = null;
    switch (type) {
      case "Polygon":
      case "Rectangle":
        // polygon coordinates are stored in array of arrays

        coords = drawFeature.getGeometry().getCoordinates()[0];
        break;
      case "Circle":
        coords = [
          drawFeature.getGeometry().getCenter(),
          drawFeature.getGeometry().getLastCoordinate(),
        ];

        /*
			Note: the following could not be used because it returns a wrong value 
				as a result of the projection
				drawFeature.getGeometry().getRadius();			

				Therefor the following implementating has been used for which 
				we store two points for circles one for the center and one for the boundary
			*/
        coordsTransformed = transformCoords(coords);
        width = sphere.getDistance(coordsTransformed[0], coordsTransformed[1]);

        break;
      case "LineString":
        coords = drawFeature.getGeometry().getCoordinates();
        break;
      default:
        throw new Error("Wrong geometry type received");
    }

    const coordsArray = [];
    _.each(coords, (coord) => {
      const newCoord = olProj.transform(coord, "EPSG:3857", "EPSG:4326");
      coordsArray.push({ longitude: newCoord[0], latitude: newCoord[1] });
    });

    return { coordinates: coordsArray, width: width };
  }

  startModifying(id, callback) {
    const $this = this;
    const feature = this.getFeature(id);
    drawFeature = feature;
    const options = {
      modifyFinished: function () {
        if (callback) {
          callback(feature);
        }
        $this.updateSelectIconGeometry(id, feature.getGeometry());
      },
    };

    this.OlMap.startModifying(this.selectIconSource, options);
  }

  endModifying() {
    this.OlMap.endModifying();
  }

  startTranslating(id, callback) {
    const $this = this;
    const feature = this.getFeature(id);
    drawFeature = feature;
    const features = new olCollection([feature]);
    const options = {
      translating: function () {
        $this.updateSelectIconGeometry(id, feature.getGeometry());
      },
      translatedFinished: function () {
        if (callback) {
          callback(feature);
        }
      },
    };

    this.OlMap.startTranslating(features, options);
  }

  endTranslating() {
    this.OlMap.endTranslating();
  }

  endAllModifications() {
    const OlMap = require("sccOlMapNew");
    OlMap.editMode = null;
    this.endModifying();
    this.endTranslating();
  }

  finishEditing() {
    this.OlMap.editMode = null;
    this.deselectFeature(true);
    this.endAllModifications();
    this.endDrawing();
  }

  setSelectGeofenceOptions(options) {
    selectGeofenceOptions = options;
  }

  resetEndReshape() {
    this.showEndBtn = false;
    this.showDragMsg = false;
  }

  removeDashStrokeFromFeature() {
    //removes the dashed stroke from the geofence shape to reset it's "Unselected state" style
    var style;
    if (drawFeature) {
      const oldStyle = drawFeature.getStyle();
      if (drawFeature.values_.geometry.constructor.name !== "LineString") {
        //ie. not a path
        style = new olStyleStyle({
          fill: new olStyleFill({
            color: oldStyle.fill_.color_,
          }),
        });
      } else {
        //makes the path line solid with default width
        style = new olStyleStyle({
          stroke: new olStyleStroke({
            color: oldStyle.fill_.color_,
          }),
        });
      }
      drawFeature.setStyle(style);
    }

    this.setDrawFeature(null);
  }

  resetFeatureStyleToPreEdit() {
    if (drawFeature) {
      drawFeature.setStyle(preEditStyle);
    }
  }

  setPreEditStyle(style) {
    preEditStyle = style;
  }

  doAfterGeofencePUTorPOST() {
    this.removeDashStrokeFromFeature();
    this.floatingFeatureOnMap = false;
    this.selectIconSource.clear();
  }
}

/**
 * gets the style to show a geofence feature with
 * @param {Object} feature feature object to be styled
 * @return {Object} new style for the feature
 */
function getGeofenceStyle(feature) {
  return new olStyleStyle({
    stroke: new olStyleStroke({
      color: getStrokeColor(feature),
      width: getStrokeWidth(feature),
    }),
    fill: new olStyleFill({
      color: getFillColor(feature),
    }),
  });
}

function getStyleGivenInclusiveActiveFlags(inclusive, active, noStroke) {
  var color = getGeofenceColor({
    inclusive: inclusive,
    active: active,
  });

  var style;
  if (!noStroke) {
    style = new olStyleStyle({
      stroke: new olStyleStroke({
        color: color,
        lineDash: [7, 7],
        width: 4,
      }),
      fill: new olStyleFill({
        color: _.concat(color, [0.6]),
      }),
    });
  } else {
    style = new olStyleStyle({
      fill: new olStyleFill({
        color: _.concat(color, [0.6]),
      }),
    });
  }
  return style;
}

function getStrokeWidth(feature) {
  const OlMap = feature.get("olMap");
  const geofenceId = feature.get("id");
  const geofence = Geofence.get(geofenceId);
  if (!(geofence.shape === "path") || !geofence.width) return 1;

  const w = geofence.width / OlMap.map.getView().getResolution();
  /*
		Note: the width is multiplied by ratio since the getResolution function 
		returns different values on different locations because of projection issue. 		
		To calculate ratio we are using the ratio of actual distance and 
		the distance shown on map for 2 first coordinates of the path
	*/

  const coords = feature.getGeometry().getCoordinates();
  const olGeomLineString = require("ol/geom/LineString").default;
  const line = new olGeomLineString([coords[0], coords[1]]);
  const coordsTransformed = transformCoords(coords);
  const wgs84Sphere = require("ol/sphere");
  //added as a temp fix to make the code work
  const distance = wgs84Sphere.getDistance(
    coordsTransformed[0],
    coordsTransformed[1]
  );
  const ratio = line.getLength() / distance;
  //till here
  // const sphere= new wgs84Sphere(6378137);
  // const ratio= line.getLength()/sphere.haversineDistance(coordsTransformed[0], coordsTransformed[1]);
  return Math.max(w * ratio, 1);
}

function transformCoords(coords) {
  return _.map(coords, (coord) => {
    return olProj.transform(coord, "EPSG:3857", "EPSG:4326");
  });
}

/**
 * gets the color for showing geofence stroke
 * @param {Object} feature feature to get the color for
 * @return {Array} color code array
 */
function getStrokeColor(feature) {
  const geofenceId = feature.get("id");
  const geofence = Geofence.get(geofenceId);
  return getGeofenceColor(geofence);
}

function getFillColor(feature) {
  const strokeColor = getStrokeColor(feature);
  return _.concat(strokeColor, [0.3]);
}

function getSelectFillColor() {
  const strokeColor = getGeofenceColor(selectGeofenceOptions);
  return _.concat(strokeColor, [0.6]);
}

function getGeofences() {
  console.log("geogeo", Geofence.get());
  return _.values(Geofence.get());
}

function getGeofenceColor(geofence) {
  //changed the following from a string equality to boolean
  if (!geofence.active) return inactiveGeofenceColor;
  return geofence.inclusive ? inclusiveGeofenceColor : exclusiveGeofenceColor;
}

export default new GeofenceOverlay({
  id: "geofence_overlay",

  // overlay title
  title: "Geofences",

  zIndex: 80,

  // whether or not features of the layer are selectable
  isSelectable: true,

  popup: {
    containerId: "olMapGeofencePopup",
  },
});
