import { default as Module } from "sccModule";
const Permission= require("sccPermission");
const log= require("loglevel");

/**
 * The base class for Address Book
 * 
 * @class AddressBook
 */
class AddressBookModule extends Module.Module{
	constructor() {
		super({
			moduleName: "address_book"
		});
	}
	init(){
		if(!Permission.default.verify("address_book", "view")){
			log.debug("User is not permitted for Address Book module");
			return Promise.resolve();
		}
		
		var eventName = "address_book";
		this.sockets = [
			"post:/"+ eventName, 
			"put:/"+ eventName, 
			"delete:/"+ eventName +"/:id/:sync_now"
		];
		
		return super.init();		
	}

	delete(data){
		var Utils= require("sccUtils");
		var options= {
			url: this.routeUrl+"/"+data.id+"/true",
			method: "DELETE",
			data: {}
		};
		return Utils.httpRequestHandler(options);
	}
}

//module.exports= New AddressBookModule();
export default new AddressBookModule();