import React, { useState, useEffect, useContext } from "react";

// External
import _ from "lodash";
import clsx from "clsx";
import { useFormikContext } from "formik";

// Material-UI
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";

// Internal
import { ModuleWrapperContext } from "../ModuleWrapperContext";
import { SelectedGeofenceContext } from "../../../modules/geofence/components/context/SelectedGeofenceContext";
import Language from "sccLanguage";
import Geofence from "sccGeofence";
import GeofenceOverlay from "sccGeofenceOverlay";
import CustomIcon from "@Components/CustomIcon";
import CustomTooltip from "@Components/CustomTooltip";
import CustomDropdown from "@Components/CustomDropdown";
import CustomButton from "@Components/CustomButton";
import CustomSearch from "@Components/CustomSearch";
import SearchMessages from "@Components/SearchMessages";
import CustomOrderBy from "@Components/CustomOrderBy";
import { moduleWrapper } from "@Styles/ModuleWrapper";
import CustomFilter from "../../CustomFilter/CustomFilter";
import CustomMessagingDropdown from "../../CustomMessagingDropdown/CustomMessagingDropdown";
import TopBar from "../../CustomAssetWrapper/TopBar";

// 1 - listing module generic
// 2 - forms for the generic module
// 3 - single page modules
// 4	- alerts
// 5	- assets
// 6 - Message module
// 7 - Iridium Billing
// 8 - Sync

const MW_LIST_MODE = process.env.REACT_APP_MW_LIST_MODE;
const MW_ADDEDIT_MODE = process.env.REACT_APP_MW_ADDEDIT_MODE;
const MW_SINGLEPAGE_MODE = process.env.REACT_APP_MW_SINGLEPAGE_MODE;
const MW_ALERTS_MODULE_MODE = process.env.REACT_APP_MW_ALERTS_MODULE_MODE;
const MW_ASSETS_MODULE_MODE = process.env.REACT_APP_MW_ASSETS_MODULE_MODE;
const MW_MESSAGE_MODULE_MODE = process.env.REACT_APP_MW_MESSAGE_MODULE_MODE;
const MW_MESSAGE_MODULE_LIST_MODE =
  process.env.REACT_APP_MW_MESSAGE_MODULE_LIST_MODE;
const MW_MESSAGE_MODULE_COMPOSE_MODE =
  process.env.REACT_APP_MW_MESSAGE_MODULE_COMPOSE_MODE;
const MW_MESSAGE_MODULE_READ_MODE =
  process.env.REACT_APP_MW_MESSAGE_MODULE_READ_MODE;
const MW_IRIDIUM_MODULE_MODE = process.env.REACT_APP_MW_IRIDIUM_MODULE_MODE;
const MW_SYNC_MODULE_MODE = process.env.REACT_APP_MW_SYNC_MODULE_MODE;

export default function ModuleWrapperHeader(props) {
  const [itemsOnPage, setItemsOnPage] = useState(20);
  const [searchFilter, setSearchFilter] = useState("");
  const { filterSelection, setFilterSelection, filteredList } = props;
  const [searchPlaceholder, setSearchPlaceholder] = useState(
    Language.translate("Search")
  );

  const [filterLabel, setFilterLabel] = useState(Language.translate("Filter"));
  const [btnBackText, setBtnBackText] = useState(Language.translate("Back"));
  const [error, setError] = useState({}); // only used for messages search validation
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const { setModuleEditMode } = useContext(SelectedGeofenceContext);
  const [groupTree] = useState(mwState.moduleData);
  const formik = useFormikContext();
  const moduleItemData = formik.values;
  const inputDataHasChanged = mwState.inputDataHasChanged;

  const onOrderSelected = (value) => {
    setMwState((p) => ({
      ...p,
      selectedOrderBy: value,
    }));
  };

  const handleDialogOpenClose = () => {
    mwState.setDialogOkAction(() => okActionClose());
    mwState.setDialogCancelAction(() => cancelAction());
    mwState.setDialogOpen(true);
  };
  const handleDialogOpenBack = () => {
    mwState.setDialogOkAction(() => okActionBack());
    mwState.setDialogCancelAction(() => cancelAction());
    mwState.setDialogOpen(true);
  };
  const handleDialogOpenBackMessage = () => {
    mwState.setDialogOkAction(() => okActionBackMessage());
    mwState.setDialogCancelAction(() => cancelAction());
    mwState.setDialogOpen(true);
  };
  function okActionClose() {
    if (mwState.moduleName === "Geofence") {
      //clears any newly drawn geofences off the map
      resetGeofenceStates();
    }

    mwState.closeModuleWrapperPopper();
    mwState.setDialogOpen(false);
  }
  function okActionBack() {
    cancelAndBackToListingFunction();
    mwState.setDialogOpen(false);
  }
  function okActionBackMessage() {
    mwState.cancelAndBackToMessageListingMode();
    mwState.setDialogOpen(false);
  }
  function cancelAction() {
    mwState.setDialogOpen(false);
  }
  function cancelAndBackToListingFunction() {
    setMwState((p) => ({
      ...p,
      moduleFormHasChanges: false,
    }));
    if (mwState.moduleName === "Geofence") {
      resetGeofenceStates();
    }

    if (mwState.moduleName !== "Device") mwState.cancelAndBackToListingMode();
    else mwState.cancelAndBackToAssetListingMode();
  }

  function resetGeofenceStates() {
    // set map background to light mode
    setModuleEditMode("end");
    window.olMap.editMode = "end";
    GeofenceOverlay.endAllModifications();
    // make sure GeofenceOverlay returns to original setting
    GeofenceOverlay.setBlockSelection(false);
    GeofenceOverlay.deselectFeature();
    // reset Geofence
    const geofenceId = mwState.moduleItemData.id;
    if (geofenceId) {
      const geofence = Geofence.get(geofenceId);
      GeofenceOverlay.refresh(geofence);
    } else {
      // if in Add mode, end drawing
      GeofenceOverlay.endDrawing();
    }
    GeofenceOverlay.resetFeatureStyleToPreEdit();
    GeofenceOverlay.floatingFeatureOnMap = false;
    GeofenceOverlay.selectIconSource.clear();
    GeofenceOverlay.setDrawFeature(null);
  }

  const arrItemsOnPage = [
    {
      value: 10,
      label: `10 ${Language.translate("PER PAGE")}`,
    },
    {
      value: 20,
      label: `20 ${Language.translate("PER PAGE")}`,
    },
    {
      value: 50,
      label: `50 ${Language.translate("PER PAGE")}`,
    },
    {
      value: 100,
      label: `100 ${Language.translate("PER PAGE")}`,
    },
  ];

  //first time run for all modules
  useEffect(() => {
    if (mwState.resetToInitialState) {
      setItemsOnPage(20);
      setSearchFilter("");
      setFilterSelection({});
      setFilterLabel(Language.translate("Filter"));
      setSearchPlaceholder(Language.translate("Search"));
      setBtnBackText(Language.translate("Back"));
      if (mwState.moduleName !== "Message") {
        mwState.moduleData &&
          filterSelectionChange(
            null,
            null,
            mwState.moduleData && Object.values(mwState.moduleData)
          );
      }
      setMwState((p) => ({ ...p, resetToInitialState: false }));
    }
  }, [mwState.resetToInitialState]);

  useEffect(() => {
    // this is used to pre filter items when user click Alert Type buttons
    if (mwState.preFilteredItem) {
      setFilterSelection(mwState.preFilteredItem);
      const filterName = Object.keys(mwState.preFilteredItem)[0];
      if (filterName === "alert_type_id") {
        filterSelectionChange(
          { target: { value: mwState.preFilteredItem.alert_type_id[0] } },
          filterName,
          mwState.moduleData,
          true
        );
      }
    } else {
      setFilterSelection({});
    }
  }, [mwState.preFilteredItem]);

  useEffect(() => {
    if (
      (mwState.wrapperDisplayMode === MW_ADDEDIT_MODE &&
        inputDataHasChanged(mwState.moduleItemDataOriginal, moduleItemData)) ||
      (mwState.wrapperDisplayMode === MW_MESSAGE_MODULE_MODE && //added a clause for messaging compose
        mwState.wrapperDisplaySubMode === MW_MESSAGE_MODULE_COMPOSE_MODE &&
        inputDataHasChanged(mwState.moduleItemDataOriginal, moduleItemData))
    ) {
      setMwState((p) => ({
        ...p,
        moduleFormHasChanges: true,
      }));
    } else {
      setMwState((p) => ({
        ...p,
        moduleFormHasChanges: false,
      }));
    }

    setMwState((p) => ({
      ...p,
      isSaveInProgress: false,
    }));
  }, [moduleItemData]);

  function handleClearSearch() {
    setSearchFilter("");

    const updatedData = filterSelection
      ? mwState.arrayToObject(
          filteredList(Object.values(mwState.moduleData), filterSelection),
          "id"
        )
      : mwState.moduleData;

    const numberOfItems = Object.keys(updatedData).length;
    setMwState((p) => ({
      ...p,
      filteredModuleData: updatedData,
      searchExecuted: false,
      pagination: {
        ...p.pagination,
        currentPage: 1,
        numberOfItems: numberOfItems,
        startIndex: 0,
        endIndex:
          numberOfItems > p.pagination.itemsPerPage
            ? p.pagination.itemsPerPage
            : numberOfItems,
      },
    }));
  }

  function updateFilteredData(data, runClearSearch) {
    const numberOfItems = data && Object.keys(data).length;
    if (runClearSearch) {
      handleClearSearch();
      return false;
    }
    setMwState((p) => ({
      ...p,
      filteredModuleData:
        mwState.title === "Message" ? Object.values(data) : data,
      searchExecuted: true,
      pagination: {
        ...p.pagination,
        currentPage: 1,
        numberOfItems: numberOfItems,
        startIndex: 0,
        endIndex:
          numberOfItems > p.pagination.itemsPerPage
            ? p.pagination.itemsPerPage
            : numberOfItems,
      },
    }));
  }

  function filterSelectionChange(e, filterName, originalData, isPreFilter) {
    let allFilters = { ...filterSelection };
    let value = null;
    setSearchFilter("");
    if (e === null) {
      //implies the call is from CLEAR
      allFilters = {};
      setFilterSelection({});
      setMwState((p) => ({
        ...p,
        selectedFilter: allFilters,
      }));
    } else {
      value = !isNaN(e.target.value)
        ? e.target.value === ""
          ? null
          : Number(e.target.value)
        : e.target.value.toString();
      if (filterName !== null) {
        if (isPreFilter) {
          allFilters = {}; //clearing any previously selected filters
          allFilters[filterName] = [value];
        } else {
          if (_.indexOf(allFilters[filterName], value) > -1) {
            allFilters[filterName] = _.difference(allFilters[filterName], [
              value,
            ]);
          } else {
            allFilters[filterName] = _.union(allFilters[filterName], [value]);
          }
        }
        setFilterSelection(allFilters);

        setMwState((p) => ({
          ...p,
          selectedFilter: allFilters,
        }));
      }
    }
    if (mwState.showFilter) {
      const data = mwState.arrayToObject(
        filteredList(Object.values(originalData), allFilters),
        "id"
      );
      updateFilteredData(data);
    }
  }

  function onItemsOnPageChange(e) {
    setItemsOnPage(e.target.value);
    //make appropriate change in the context
    setMwState((p) => ({
      ...p,
      pagination: {
        ...p.pagination,
        currentPage: 1, //reset the current page to 1
        itemsPerPage: e.target.value, //based on the selection
        numberOfItems: Object.keys(p.filteredModuleData).length, //based on the items in the list right now
        startIndex: 0,
        endIndex:
          Object.keys(p.filteredModuleData).length > e.target.value
            ? e.target.value
            : Object.keys(p.filteredModuleData).length,
      },
    }));
  }

  //messaging module specific
  function onCheckAllChange(e, filteredModuleData) {
    const arrChecked = e.target.checked
      ? filteredModuleData.map((data) => data.id)
      : [];
    setMwState((p) => ({
      ...p,
      moduleData: { ...p.moduleData, checked: arrChecked },
    }));
  }
  function onItemsOnPageMessagingChange(e) {
    setItemsOnPage(e.target.value);
    //make appropriate change in the context
    const options = [
      1,
      e.target.value,
      mwState.moduleData.currentFolder,
      mwState.moduleData.sortDesc,
    ];
    if (mwState.searchExecuted) options[4] = mwState.searchText;
    mwState.loadMessages(options).then((data) => {
      setMwState((p) => ({
        ...p,
        pagination: {
          ...p.pagination,
          itemsPerPage: e.target.value,
        },
      }));
    });
  }
  function handleAction(action) {
    const options = [
      mwState.pagination.itemsPerPage || 1,
      mwState.pagination.itemsPerPage,
      mwState.moduleData.currentFolder,
      mwState.moduleData.sortDesc,
    ];
    if (mwState.searchExecuted) options[4] = mwState.searchText;
    //used for reloading
    // mwState.messageModuleLoading();commented out as the actions with a dialog question otherwise get stuck in loading mode
    switch (action) {
      case 1: //Move to Trash
        // mwState.updateModuleData({folder: mwState.moduleData.currentFolder, id: mwState.moduleData.checked, message_archived: true}, '/api/v1/message', {title: 'MESSAGE', text: 'Moved messages to trash', variant:'info', noRefresh: true})
        mwState.moveMessagesToTrash(mwState.moduleData.checked, options);
        break;
      case 2: //Mark as Read
        mwState.markMessagesToRead(mwState.moduleData.checked, options);
        break;
      case 3: //Mark as Unread
        mwState.markMessagesToUnread(mwState.moduleData.checked, options);
        break;
      case 4: //Delete from Trash
        // Are you sure you want to permanately delete the messages?
        // mwState.updateModuleData({folder: mwState.moduleData.currentFolder, id: mwState.moduleData.checked, message_deleted: true}, '/api/v1/message', {title: 'MESSAGE', text: 'Messages deleted from trash', variant:'info', noRefresh: true})
        handleDialogOpenEmptyTrash(options);
        break;
      case 5: //Restore Message
        // mwState.updateModuleData({folder: mwState.moduleData.currentFolder, id: mwState.moduleData.checked, message_archived: false}, '/api/v1/message', {title: 'MESSAGE', text: 'Restored messages', variant:'info', noRefresh: true})
        mwState.restoreDeletedMessages(mwState.moduleData.checked, options);
        break;
      case 6: //Hide Unauthorized
        setMwState((p) => ({
          ...p,
          moduleData: {
            ...p.moduleData,
            hideUnauthorized: true,
            reloading: false,
          },
        }));
        break;
      case 7: //View Unauthorized
        setMwState((p) => ({
          ...p,
          moduleData: {
            ...p.moduleData,
            hideUnauthorized: false,
            reloading: false,
          },
          // filteredModuleData: {...p.moduleData.messages}
        }));
        break;
      default: //Nothing
        break;
    }
  }
  const handleDialogOpenEmptyTrash = (options) => {
    mwState.setDialogOkAction(() =>
      mwState.deleteMessagesFromTrash(mwState.moduleData.checked, options)
    );
    mwState.setDialogCancelAction(() => cancelAction());
    mwState.setDialogOpen(true);
  };
  const actions = [
    {
      label: Language.translate("Move to Trash"),
      value: 1,
      disabled:
        mwState.moduleData &&
        mwState.moduleData.checked &&
        mwState.moduleData.checked.length === 0,
      visible: ["inbox", "sent"],
    },
    {
      label: Language.translate("Mark as Read"),
      value: 2,
      disabled:
        mwState.moduleData &&
        mwState.moduleData.checked &&
        mwState.moduleData.checked.length === 0,
      visible: ["inbox"],
    },
    {
      label: Language.translate("Mark as Unread"),
      value: 3,
      disabled:
        mwState.moduleData &&
        mwState.moduleData.checked &&
        mwState.moduleData.checked.length === 0,
      visible: ["inbox"],
    },
    {
      label: Language.translate("Delete from Trash"),
      value: 4,
      disabled:
        mwState.moduleData &&
        mwState.moduleData.checked &&
        mwState.moduleData.checked.length === 0,
      visible: ["trash"],
    },
    {
      label: Language.translate("Restore Message"),
      value: 5,
      disabled:
        mwState.moduleData &&
        mwState.moduleData.checked &&
        mwState.moduleData.checked.length === 0,
      visible: ["trash"],
    },
    {
      label: Language.translate("Hide Unauthorized"),
      value: 6,
      disabled: false,
      visible:
        mwState.moduleData && !mwState.moduleData.hideUnauthorized
          ? ["inbox", "sent", "trash"]
          : [],
    },
    {
      label: Language.translate("View Unauthorized"),
      value: 7,
      disabled: false,
      visible:
        mwState.moduleData && mwState.moduleData.hideUnauthorized
          ? ["inbox", "sent", "trash"]
          : [],
    },
  ];
  // debounce is no longer needed as we call this only on click
  const handleMessagesSearch = (value) => {
    //added this check to make sure that search is not run for the last char always when we delete search text one char at a time
    if (value !== "") {
      setMwState((p) => ({
        ...p,
        moduleData: {
          ...p.moduleData,
          reloading: true,
        },
        pagination: { ...p.pagination, currentPage: 1 },
        searchExecuted: true,
      }));
      mwState.loadMessages([
        1,
        mwState.pagination.itemsPerPage,
        mwState.moduleData.currentFolder,
        mwState.moduleData.sortDesc,
        value,
      ]);
    } else {
      handleClearMessagesSearch();
    }
  };

  const handleMessagesSearchChange = () => {
    const searchString = mwState.searchText;
    if (searchString === "") {
      setError({});
      handleClearMessagesSearch();
    } else {
      const Yup = require("yup");
      const regexSpecialChar = /^[^<>\\|;:[\]{}=~`&'"%*^=#!@$()]+$/;
      const schema = Yup.object({
        searchString: Yup.string().matches(
          regexSpecialChar,
          Language.translate("Special characters are not allowed")
        ),
      });
      schema
        .validate({ searchString })
        .then((data) => {
          setError({});
          handleMessagesSearch(data.searchString);
          return true;
        })
        .catch((e) => {
          setError({ message: e.message });
          setMwState((p) => ({
            ...p,
            searchText: searchString,
          }));
        });
    }
  };

  const handleClearMessagesSearch = () => {
    setMwState((p) => ({
      ...p,
      moduleData: {
        ...p.moduleData,
        reloading: true,
      },
      pagination: { ...p.pagination, currentPage: 1 },
      searchExecuted: false,
      searchText: "",
    }));
    //reloading the data based on no search text
    mwState.loadMessages([
      1,
      mwState.pagination.itemsPerPage,
      mwState.moduleData.currentFolder,
      mwState.moduleData.sortDesc,
    ]);
    return false;
  };
  // messaging module specifics end here

  const useStyles = makeStyles((theme) => ({
    ...moduleWrapper(theme),
    specialDropdown: {
      width: "105px !important",
      "& div svg": {
        top: "inherit",
      },
    },
    searchButton: {
      width: 30,
      minWidth: "30px !important",
      height: 30,
      marginLeft: -8,
      borderRadius: 3,
    },
  }));
  const classes = useStyles();

  switch (mwState.wrapperDisplayMode) {
    case MW_SYNC_MODULE_MODE: // Sync
      return (
        <Grid
          justifyContent="space-between"
          className={classes.header}
          container
        >
          <Grid container justifyContent="flex-start" item xs={8}>
            <Grid item className="iconBox">
              <CustomTooltip
                title={Language.translate(mwState.title)}
                placement="left"
              >
                <span className="title">
                  <CustomIcon id={`icon-${mwState.icon}`} />
                </span>
              </CustomTooltip>
            </Grid>
            {mwState.showFilter && (
              <Grid item className="filter">
                <CustomFilter
                  name="filter"
                  label={filterLabel}
                  filterSelection={filterSelection}
                  onChange={filterSelectionChange}
                  originalData={
                    mwState.moduleData && Object.values(mwState.moduleData)
                  }
                  filterField={mwState.filterField}
                />
              </Grid>
            )}
            {mwState.showOrderBy && (
              <Grid item className="orderBy">
                <CustomOrderBy
                  orderBySelection={mwState.orderBySelection}
                  selectedOrderBy={mwState.selectedOrderBy}
                  onOrderBySelected={onOrderSelected}
                />
              </Grid>
            )}
            {mwState.showPagination &&
              mwState.pagination &&
              mwState.pagination.numberOfItems > 10 && (
                <Grid item className="recordsPerPage">
                  <CustomDropdown
                    id="pagingSelector"
                    className="dropdown"
                    value={itemsOnPage}
                    onChange={onItemsOnPageChange}
                  >
                    {arrItemsOnPage.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </CustomDropdown>
                </Grid>
              )}
          </Grid>
          <Grid container justifyContent="flex-end" item xs={4}>
            <Grid item xs={10} align="right" className="searchFilter">
              {mwState.showSearch && (
                <CustomSearch
                  id="txtSearch"
                  name="search"
                  placeholder={searchPlaceholder}
                  className="textfield"
                  value={searchFilter}
                  onChange={(e) => setSearchFilter(e.target.value)}
                  filters={filterSelection}
                  filteredList={filteredList}
                  data={mwState.moduleData && Object.values(mwState.moduleData)}
                  updateFilteredData={updateFilteredData}
                  searchField={mwState.searchField}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        id="clearSearch"
                        onClick={handleClearSearch}
                        style={{ cursor: "pointer" }}
                      >
                        <ClearIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Grid>
            <Grid item xs={2} align="right">
              <CustomTooltip
                title={Language.translate("Close Menu")}
                placement="bottom"
              >
                <IconButton
                  onClick={
                    !mwState.moduleFormHasChanges
                      ? mwState.closeModuleWrapperPopper
                      : handleDialogOpenClose
                  }
                  color="inherit"
                  className="closeIcon"
                >
                  <CloseIcon />
                </IconButton>
              </CustomTooltip>
            </Grid>
          </Grid>
        </Grid>
      );
    case MW_LIST_MODE: //list mode
    case MW_SINGLEPAGE_MODE: //single page mode
    case MW_IRIDIUM_MODULE_MODE: //Iridium Billing
    case MW_ALERTS_MODULE_MODE: //alerts
    default:
      return (
        <Grid
          justifyContent="space-between"
          className={classes.header}
          container
        >
          <Grid container justifyContent="flex-start" item xs={6}>
            <Grid item xs={2} className="iconBox">
              <CustomTooltip
                title={Language.translate(mwState.title)}
                placement="left"
              >
                <span className="title">
                  <CustomIcon id={`icon-${mwState.icon}`} />
                </span>
              </CustomTooltip>
            </Grid>
            {mwState.showFilter && (
              <Grid item xs={5} className="filter">
                <CustomFilter
                  name="filter"
                  label={filterLabel}
                  filterSelection={filterSelection}
                  onChange={filterSelectionChange}
                  originalData={
                    mwState.moduleData && Object.values(mwState.moduleData)
                  }
                  filterField={mwState.filterField}
                />
              </Grid>
            )}
            {mwState.showPagination &&
              mwState.pagination &&
              mwState.pagination.numberOfItems > 10 && (
                <Grid item xs={5} className="recordsPerPage">
                  <CustomDropdown
                    id="pagingSelector"
                    className="dropdown"
                    value={itemsOnPage}
                    onChange={onItemsOnPageChange}
                  >
                    {arrItemsOnPage.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </CustomDropdown>
                </Grid>
              )}
          </Grid>
          <Grid container justifyContent="flex-end" item xs={6}>
            <Grid item xs={10} align="right" className="searchFilter">
              {mwState.showSearch && (
                <CustomSearch
                  id="txtSearch"
                  name="search"
                  placeholder={searchPlaceholder}
                  className="textfield"
                  value={searchFilter}
                  onChange={(e) => setSearchFilter(e.target.value)}
                  filters={filterSelection}
                  filteredList={filteredList}
                  data={mwState.moduleData && Object.values(mwState.moduleData)}
                  updateFilteredData={updateFilteredData}
                  searchField={mwState.searchField}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        id="clearSearch"
                        onClick={handleClearSearch}
                        style={{ cursor: "pointer" }}
                      >
                        <ClearIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Grid>
            <Grid item xs={2} align="right">
              <CustomTooltip
                title={Language.translate("Close Menu")}
                placement="bottom"
              >
                <IconButton
                  onClick={
                    !mwState.moduleFormHasChanges
                      ? mwState.closeModuleWrapperPopper
                      : handleDialogOpenClose
                  }
                  color="inherit"
                  className="closeIcon"
                >
                  <CloseIcon />
                </IconButton>
              </CustomTooltip>
            </Grid>
          </Grid>
        </Grid>
      );
    case MW_ADDEDIT_MODE: //edit mode
      return (
        <Grid
          justifyContent="space-between"
          className={classes.header}
          container
        >
          <Grid container justifyContent="flex-start" item xs={6}>
            <Grid item xs={2} className="iconBox">
              <CustomTooltip
                title={Language.translate(mwState.title)}
                placement="left"
              >
                <span className="title">
                  <CustomIcon id={`icon-${mwState.icon}`} />
                </span>
              </CustomTooltip>
            </Grid>
            <Grid item xs={10} className="backButton">
              <CustomButton
                id="btnBack"
                onClick={
                  !mwState.moduleFormHasChanges
                    ? cancelAndBackToListingFunction
                    : handleDialogOpenBack
                }
                size="medium"
                color="back"
                variant="contained"
                startIcon={<ArrowBackIosIcon />}
              >
                {btnBackText}
              </CustomButton>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end" item xs={6}>
            <Grid item xs={2} align="right">
              <CustomTooltip
                title={Language.translate("Close Menu")}
                placement="bottom"
              >
                <IconButton
                  onClick={(evt) => {
                    if (!mwState.moduleFormHasChanges) {
                      mwState.closeModuleWrapperPopper(evt);
                      GeofenceOverlay.resetFeatureStyleToPreEdit();
                    } else handleDialogOpenClose(evt);
                  }}
                  color="inherit"
                  className="closeIcon"
                >
                  <CloseIcon />
                </IconButton>
              </CustomTooltip>
            </Grid>
          </Grid>
        </Grid>
      );
    case MW_ASSETS_MODULE_MODE: //assets
      return (
        <Grid
          justifyContent="space-between"
          className={classes.header}
          container
        >
          <Grid
            container
            justifyContent="flex-start"
            item
            xs={1}
            className="iconBox"
          >
            <CustomTooltip
              title={Language.translate(mwState.title)}
              placement="left"
            >
              <span className="title">
                <CustomIcon id={`icon-${mwState.icon}`} />
              </span>
            </CustomTooltip>
          </Grid>
          <Grid item xs={10}>
            <TopBar
              name="topBar"
              filterLabel={filterLabel}
              searchPlaceholder={searchPlaceholder}
              searchFilter={searchFilter}
              setSearchFilter={setSearchFilter}
              filterSelection={filterSelection}
              setFilterSelection={setFilterSelection}
              data={groupTree}
              filteredData={mwState.filteredModuleData}
              filteredList={filteredList}
            />
          </Grid>
          <Grid item xs={1} align="right">
            <CustomTooltip
              title={Language.translate("Close Menu")}
              placement="bottom"
            >
              <IconButton
                onClick={
                  !mwState.moduleFormHasChanges
                    ? mwState.closeModuleWrapperPopper
                    : handleDialogOpenClose
                }
                color="inherit"
                className="closeIcon"
              >
                <CloseIcon />
              </IconButton>
            </CustomTooltip>
          </Grid>
        </Grid>
      );
    case MW_MESSAGE_MODULE_MODE: //message module
      const moduleData = mwState.moduleData || { messages: {}, checked: [] };
      const filteredModuleData =
        moduleData.messages &&
        mwState.convertAndSort(Object.values(moduleData.messages), []);
      return (
        <Grid
          justifyContent="space-between"
          className={classes.header}
          container
        >
          <Grid container justifyContent="flex-start" item xs={2}>
            <Grid item xs={4} className="iconBox">
              <CustomTooltip
                title={Language.translate(mwState.title)}
                placement="left"
              >
                <span className="title">
                  <CustomIcon id={`icon-${mwState.icon}`} />
                </span>
              </CustomTooltip>
            </Grid>
            {mwState.wrapperDisplaySubMode ===
              MW_MESSAGE_MODULE_COMPOSE_MODE && (
              <Grid item xs={4} className="backButton">
                <CustomButton
                  id="btnBack"
                  onClick={
                    !mwState.moduleFormHasChanges
                      ? mwState.cancelAndBackToMessageListingMode
                      : handleDialogOpenBackMessage
                  }
                  size="medium"
                  color="back"
                  variant="contained"
                  startIcon={<ArrowBackIosIcon />}
                >
                  {btnBackText}
                </CustomButton>
              </Grid>
            )}
            {mwState.wrapperDisplaySubMode === MW_MESSAGE_MODULE_LIST_MODE &&
              mwState.showItemsPerPage && (
                <Grid item xs={8} className="recordsPerPage">
                  <CustomDropdown
                    id="pagingSelector"
                    className={clsx(classes.specialDropdown, "dropdown")}
                    value={itemsOnPage}
                    onChange={onItemsOnPageMessagingChange}
                  >
                    {arrItemsOnPage.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </CustomDropdown>
                </Grid>
              )}
          </Grid>
          <Grid container item xs={5}>
            {mwState.wrapperDisplaySubMode === MW_MESSAGE_MODULE_LIST_MODE &&
              mwState.showSelectionDropdown && (
                <Grid item className="filter">
                  <CustomMessagingDropdown
                    name="filter"
                    label={Language.translate("All").toUpperCase()}
                    color="primary"
                    actions={actions.filter(
                      (obj) =>
                        obj.visible.indexOf(mwState.moduleData.currentFolder) >
                        -1
                    )}
                    onCheckAllChange={(e) =>
                      onCheckAllChange(e, filteredModuleData)
                    }
                    checked={
                      moduleData &&
                      filteredModuleData &&
                      moduleData.checked.length !== 0 &&
                      moduleData.checked.length === filteredModuleData.length
                    }
                    indeterminate={
                      moduleData &&
                      filteredModuleData &&
                      moduleData.checked.length > 0 &&
                      moduleData.checked.length < filteredModuleData.length
                    }
                    handleAction={handleAction}
                  />
                </Grid>
              )}
            {mwState.wrapperDisplaySubMode === MW_MESSAGE_MODULE_READ_MODE && (
              <Grid item className="backButton">
                <CustomButton
                  id="btnBack"
                  onClick={mwState.cancelAndBackToMessageListingMode}
                  size="medium"
                  color="back"
                  variant="contained"
                  startIcon={<ArrowBackIosIcon />}
                >
                  {Language.translate("Back to").toUpperCase() +
                    " " +
                    Language.translate(mwState.moduleData.currentFolder)}
                </CustomButton>
              </Grid>
            )}
          </Grid>
          <Grid container justifyContent="flex-end" item xs={5}>
            <Grid item xs={10} align="right" className="searchFilter">
              {mwState.wrapperDisplaySubMode === MW_MESSAGE_MODULE_LIST_MODE &&
                mwState.showSearch && (
                  <SearchMessages
                    id="txtSearch"
                    name="search"
                    placeholder={searchPlaceholder}
                    className="textfield"
                    value={mwState.searchText}
                    disabled={mwState.moduleData.reloading}
                    onChange={(e) =>
                      setMwState((p) => ({
                        ...p,
                        searchText: e.target.value,
                        searchComplete: false,
                      }))
                    }
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        handleMessagesSearchChange();
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CustomButton
                            id="btnSearch"
                            onClick={handleMessagesSearchChange}
                            disabled={mwState.moduleData.reloading}
                            size="small"
                            color="command"
                            variant="contained"
                            className={classes.searchButton}
                          >
                            <SearchIcon />
                          </CustomButton>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          id="clearSearch"
                          onClick={handleClearMessagesSearch}
                          style={{ cursor: "pointer" }}
                        >
                          <ClearIcon />
                        </InputAdornment>
                      ),
                    }}
                    error={!_.isEmpty(error.message)}
                    helperText={error.message}
                  />
                )}
            </Grid>
            <Grid item xs={2} align="right">
              <CustomTooltip
                title={Language.translate("Close Menu")}
                placement="bottom"
              >
                <IconButton
                  onClick={
                    !mwState.moduleFormHasChanges
                      ? mwState.closeModuleWrapperPopper
                      : handleDialogOpenClose
                  }
                  color="inherit"
                  className="closeIcon"
                >
                  <CloseIcon />
                </IconButton>
              </CustomTooltip>
            </Grid>
          </Grid>
        </Grid>
      );
  }
}
