// External
import _ from "lodash";
import $ from "jquery";

// Internal
import Menu from "sccMenu";
import Language from "sccLanguage";
import Alert from "sccAlert";
import Device from "sccDevice";
import UserSetting from "sccUserSetting";
import GuiUtils from "sccGuiUtils";
import Utils from "sccUtils";
import DeviceOverlay from "sccDeviceOverlay";
import AlertMenuMap from "./AlertMenuMap";

/**
 * Alert menu class
 *
 * @class AlertMenu
 */
class AlertMenu extends Menu {
  /**
   * AlertMenu constructor function
   *
   */
  constructor() {
    const options = {
      tab: "Alert",
      title: "Alert",
      mainClass: "Alert",
      filterByClient: true,
      imageClass: "bell",
      moduleName: "alert",
      module: Alert,
      mapLayer: DeviceOverlay,
      showPagination: true,
    };

    super(options);
    this.filteredAlerts = [];
    this.activeTab = 0;
    this.showInfo = true;
    this.toggleSound = false;
    this.lastReportedDays = 5;
    this.toggleAllOldAlerts = false;
  }

  init($scope) {
    $scope.Language = Language;
    $scope.Clock = require("sccClock");
    $scope.AlertMenu = require("sccAlertMenu");
    $scope.Alert = require("sccAlert");
    $scope.TimeUtils = require("sccTimeUtils");
    $scope.Geofence = require("sccGeofence").default;
    $scope.Device = Device;
    $scope.GuiUtils = require("sccGuiUtils");
    $scope.Cargo = require("sccCargo");
    $scope.UserSetting = require("sccUserSetting").default;
    $scope.DeviceOverlay = DeviceOverlay;
    $scope.User = require("sccUser");
    $scope.OlMap = require("sccOlMapNew");
    $scope.Utils = Utils;
    $scope._ = _;

    return super.init($scope).then(() => {
      //Note: this is a workaround for IE not loading when
      // alert audio is on
      setTimeout(() => {
        this.manageSoundForEmergencyAlerts(false);
      }, 5000);
      this.toggleFavicon();

      this.menuInitialized = true;
      $scope.menuInitialized = true;
      return Promise.resolve();
    });
  }

  /**
   * overrides open method to remove any filters on Alerts
   * @param {*}
   */
  open(alertType, deviceId) {
    // Delete filter options if not Alert tabs
    if (_.isUndefined(alertType) && _.isUndefined(deviceId)) {
      this._options.filters = [];
    }
    super.open();
  }
  /**
   * Starts the audio sound
   * Loops for continuous play
   * Always resets from 0
   * Sets variable to true for sound icon in Emergency Alert Tab
   *
   */
  playAlertSound() {
    const audio = document.getElementById("emergencyAlertSound");

    this.toggleSound = true;
    const playPromise = audio.play();
    if (playPromise !== undefined) {
      playPromise
        .then((_) => {
          audio.play();
        })
        .catch((error) => {
          document.getElementById("audioConfirmOverLay").style.display =
            "block";
          document.getElementById("audioConfirmBox").style.display = "flex";
        });
    }
  }

  /**
   * Pauses the alarm sound
   * Sets variable to false for sound icon in Emergency Alert Tab
   */
  pauseAlertSound() {
    const audio = document.getElementById("emergencyAlertSound");
    if (audio) {
      this.toggleSound = false;
      audio.pause();
    }
  }

  /**
   * Toggles the alarm sound
   */
  toggleAlertSound() {
    if (!this.toggleSound) {
      this.playAlertSound();
    } else {
      this.pauseAlertSound();
    }
  }

  /**
   * Counts ONLY Emergency alerts and returns the number of emergency alerts
   */
  countAllEmergencyAlerts() {
    const Alerts = Alert.get();
    if (Alerts && Object.keys(Alerts)?.length > 0) {
      const allAlerts = Object.keys(Alerts).map((key) => Alerts[key]);
      const EmergencyAlerts = allAlerts.filter(
        (alert) => alert.type === "Emergency"
      );
      return Object.keys(EmergencyAlerts).length;
    } else {
      return 0;
    }
  }

  /**
   * Manages sound based on whether the emergency alerts exist and if user has 'alarm audio' (on/off).
   * If checkAudio is true it will only play sound if sound is already in play (used during delete scenario)
   * If checkAudio is false it will play sound regardless whether it was paused by user or not
   */
  manageSoundForEmergencyAlerts(newAlert) {
    //const audio = angular.element(document.getElementById("emergencyAlertSound"));
    const alertCount = this.countAllEmergencyAlerts();
    // Check the users settings 'Emergency Audio' is true
    if (alertCount > 0 && UserSetting.get("panic_audio")) {
      if (newAlert) {
        this.playAlertSound();
      } else {
        this.pauseAlertSound();
      }
    }
  }
  /**
   *
   * Toggles the favicon icon and shows the total number
   * of alerts in the browsers tab
   *
   */
  toggleFavicon() {
    var iconNew = "../favicon-alert.ico";
    if (_.keys(Alert.get()).length > 0) {
      document.getElementById("favicon").href = iconNew;
      document.title =
        "(" +
        _.keys(Alert.get()).length +
        ") " +
        Language.translate("Alerts") +
        " | TITAN BY NORTAC " +
        Language.translate("Platform");
    } else {
      document.getElementById("favicon").href = "../favicon.ico";
      document.title = "TITAN BY NORTAC " + Language.translate("Platform");
    }
  }

  /**
   * Returns all the id's of Alerts that are not acknowledged in an array
   */
  getAlertIds() {
    var ids = [];

    _.forEach(this.filteredAlerts, function (value) {
      if (!Alert.isUserAcknowledged(value)) {
        ids.push(parseInt(value.id));
      }
    });

    return ids;
  }

  /**
   * Acknowledge All Alerts from ALert list
   * @param {Array} alertIds unacknowledged Alert ids in an array
   * @return {}
   */
  acknowledgeAlert(alertIds) {
    if (!alertIds.length) {
      Utils.notify({
        message: Language.translate("No alerts to acknowledge"),
        header: Language.translate("Alerts"),
        type: "error",
      });
      return;
    }

    GuiUtils.confirmBox(
      "<p class='text-center'>" +
        Language.translate("Are you sure you would like to acknowledge") +
        " " +
        alertIds.length +
        " " +
        Language.translate("Alerts") +
        "?</p>",
      function () {
        return Alert.acknowledge(alertIds);
      }
    );
  }

  /**
   * Returns Alert object and adds device name
   */
  getAlertList() {
    _.each(Alert.get(), (alert) => {
      alert.device_title = Device.get(alert.device_id).name;
    });
    return Alert.get();
  }

  /**
   * Returns all the specific alerts for individual cargo alerts
   * @param {*} deviceId Get device id for
   * @param {*} alertTypeId alert type
   * @return {array}  Ids of cargo alerts
   */
  getCargoAlerts(deviceId, alertTypeId) {
    const alerts = _.filter(Alert.get(), { device_id: deviceId });
    var ids = [];
    _.forEach(alerts, function (value) {
      if (value.info.cargo_alert_type_id === alertTypeId) {
        ids.push(parseInt(value.info.cargo_alert_type_id));
      }
    });
    return ids;
  }

  //Get Cargo Alert Type
  getCargoAlertIcon(alert) {
    var icon;
    switch (alert.info.cargo_alert_type_id) {
      case 1:
        //icon = "fa-folder-open";
        break;
      case 2:
        icon = "fa-thermometer-empty";
        break;
      case 3:
        icon = "fa-bolt";
        break;
      case 4:
        icon = "fa-tint";
        break;
      case 5:
        icon = "fa-battery-three-quarters";
        break;
      default:
        break;
    }
    return icon;
  }

  countFilteredEmergencyAlerts() {
    const alerts = [];

    var lastReportedDays = this.lastReportedDays;
    var checkDays = function (id) {
      const Device = require("sccDevice").default;
      const moment = require("moment");
      // Get the last reported timestamp and convert with moment
      const lastReport = moment.unix(Device.get(id).report_timestamp);
      // Get the difference with current time in days
      var diff = moment().diff(lastReport, "days");

      if (diff === lastReportedDays) return true;
      return false;
    };

    _.each(this.filteredAlerts, function (value) {
      if (
        value.type === "Emergency" &&
        !value.info.is_reset &&
        value.info.reset_user_id &&
        checkDays(value.device_id)
      ) {
        alerts.push(value);
      }
    });
    return alerts.length > 0;
  }

  showHideResettingAlertBtn() {
    const Permission = require("sccPermission");
    const check =
      Permission.default.verify("alarm", "reset") && this.toggleAllOldAlerts;
    if (check) return true;
    return false;
  }

  showHideAllOldAlertBtn() {
    this.toggleAllOldAlerts = !this.toggleAllOldAlerts;
  }

  showHideBtn(id) {
    const Device = require("sccDevice").default;
    const moment = require("moment");
    // Get the last reported timestamp and convert with moment
    const lastReport = moment.unix(Device.get(id).report_timestamp);
    // Get the difference with current time in days
    var diff = moment().diff(lastReport, "days");

    if (diff === this.lastReportedDays) return true;
    return false;
  }

  /**
   * shows map window for location where alert is triggered
   * @param {Object} alert alert object
   */
  showAlertMap(alert) {
    $("#alertMapView").empty();
    AlertMenuMap.init().then(() => {
      const latitude = alert.info.trigger_latitude;
      const longitude = alert.info.trigger_longitude;
      const zoom = 4;
      const coord = [longitude, latitude];
      AlertMenuMap.addMarker([coord], coord, zoom);
    });
  }
}

export default new AlertMenu();
