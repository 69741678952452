import React, { useContext, useState } from "react";

// External
import _ from "lodash";
import { Formik, Form } from "formik";
import { makeStyles } from "@material-ui/core/styles";

// Internal
import Language from "sccLanguage";
import { ModuleWrapperContext } from "./ModuleWrapperContext";
import ModuleWrapperHeader from "./ModuleWrapperHeader";
import ModuleWrapperBody from "./ModuleWrapperBody";
import ModuleWrapperFooter from "./ModuleWrapperFooter";
import CustomDialog from "@Components/CustomDialog/index.js";
import { moduleWrapper } from "@Styles/ModuleWrapper";

function ModuleWrapper(props) {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  let schema = require(`../../validation/${mwState.moduleName.replace(
    " ",
    ""
  )}Validation`).default;

  const [filterSelection, setFilterSelection] = useState({});

  function filteredList(list, filters) {
    _.each(_.keys(filters), function (filterKey) {
      if (!filters[filterKey]) {
        return list;
      }
      if (_.isArray(filters[filterKey])) {
        if (filters[filterKey].length === 0) {
          return list;
        }
        list = _.reduce(
          list,
          function (result, item) {
            if (_.indexOf(filters[filterKey], item[filterKey]) > -1) {
              result = _.concat(result, item);
            }
            return result;
          },
          []
        );
      }
    });
    return list;
  }

  //Fix introduced due to different nature of Asset module.
  //This fix will help load the group form validation when a Group is being added or edited
  if (mwState.moduleName === "Device") {
    if (mwState.moduleItemData && mwState.moduleItemData.formType === "Group") {
      schema = require(`../../validation/GroupValidation`).default;
    }
  }
  const handleDialogOkAction = () => {
    mwState.handleDialogOkAction();
    mwState.setDialogOpen(false);
  };
  const handleDialogCancelAction = () => {
    mwState.handleDialogCancelAction();
    mwState.setDialogOpen(false);
  };

  // this function is to update filteredModuleData at the time of rerender
  const updatedFilteredModuleData = (filteredData, moduleData, action, ids) => {
    if (!filteredData && Object.keys(filteredData).length === 0) {
      return moduleData;
    }

    let filteredDataKeys = Object.keys(filteredData);

    let newFilteredData = {};
    if (action === "add") {
      if (ids?.length > 0) {
        ids.map((id) => (newFilteredData[id] = moduleData[id]));
      }
    } else if (action === "delete") {
      if (ids?.length > 0) {
        filteredDataKeys = filteredDataKeys.filter(function (el) {
          return ids.indexOf(el) < 0;
        });
      }
    }

    filteredDataKeys.forEach((key) => {
      if (moduleData[key]) {
        newFilteredData[key] = moduleData[key];
      }
    });

    return newFilteredData;
  };

  function reRender(action, ids) {
    if (mwState.moduleName !== "Message" && mwState.moduleName !== "Device") {
      //only executing in case of other than messages and assets module as they handle data completely differently
      // Message module is taken care of in the loadMessages (for messaging) in context
      //Assets module is taken care of in else section below

      let newdata = updatedFilteredModuleData(
        mwState.filteredModuleData,
        mwState.moduleData,
        action,
        ids
      );

      // check if there are filters
      if (!_.isEmpty(filterSelection)) {
        newdata = mwState.arrayToObject(
          filteredList(Object.values(newdata), filterSelection),
          "id"
        );
      }
      const numberOfItems = newdata ? Object.keys(newdata).length : 0;


      setMwState((p) => ({
        ...p,
        filteredModuleData: newdata,
        pagination: {
          ...p.pagination,
          numberOfItems: numberOfItems,
          endIndex:
            numberOfItems < p.pagination.itemsPerPage
              ? numberOfItems
              : p.pagination.endIndex,
        },
      }));
    } else if (mwState.moduleName === "Device") {
      //handling Assets module
      setMwState((p) => ({
        ...p,
        //Assets module is a special case as it does not work like the rest of the MW modules.
        //The reRender is thus handled in the TopBar where the filter and search values exist
        reRenderAssetsModule: true,
      }));
    }
  }

  function callerFunc(e) {
    const action = e.target.dataset.action;
    let originator = e.target.dataset.originator;
    const ids =
      e.target.dataset.ids !== "" ? e.target.dataset.ids.split(", ") : [];
    const activeModuleName = mwState.moduleName.replace(" ", "_").toLowerCase();

    //a small hack for the Assets module since it handles both Groups and devices and the module is named Device
    if (originator === "group") originator = "device";
    /////

    if (originator !== "user_setting" && originator === activeModuleName) {
      reRender(action, ids);
    }
  }

  const width = mwState.title === "Message" ? 1000 : 750;
  const useStyles = makeStyles((theme) => ({
    ...moduleWrapper(theme),
  }));
  const classes = useStyles({ width });

  return (
    <React.Fragment>
      <Formik
        initialValues={mwState.moduleItemData}
        validationSchema={schema}
        enableReinitialize={true}
      >
        <div className={classes.container}>
          <Form>
            <ModuleWrapperHeader
              filterSelection={filterSelection}
              setFilterSelection={setFilterSelection}
              filteredList={filteredList}
            />
            <ModuleWrapperBody />
            <ModuleWrapperFooter
              filterSelection={filterSelection}
              setFilterSelection={setFilterSelection}
              filteredList={filteredList}
            />
            <CustomDialog
              open={mwState.dialogOpen}
              onCancelAction={handleDialogCancelAction}
              onOkAction={handleDialogOkAction}
              text={
                mwState.dialogMessage ||
                Language.translate(
                  "Your current changes will be lost. Do you want to continue?"
                )
              }
            />
            <button
              style={{ display: "none" }}
              onClick={callerFunc}
              type="button"
              id="hiddenBtn"
            >
              Hidden Button
            </button>
          </Form>
        </div>
      </Formik>
    </React.Fragment>
  );
}

export default ModuleWrapper;
