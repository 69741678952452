import React, { Component } from "react";
import DataDisplayButtonGroup from "./DataDisplayButtonGroup";
import GeofenceInfo from "./GeofenceInfo";
import DataDisplayHeader from "./DataDisplayHeader";
import Paper from "@material-ui/core/Paper";
import MinimizeContextProvider from "../context/MinimizeContext.js";

const Geofence = require("sccGeofence").default;
const GeofenceOverlay = require("sccGeofenceOverlay").default;

export default class DataDisplay extends Component {
  constructor() {
    super();
    this.state = {
      device: null,
    };

    if (!DataDisplay.publicInstance) {
      DataDisplay.publicInstance = this;
    }
  }

  static publicInstance;

  static updateFeatureSelected() {
    setTimeout(() => {
      DataDisplay.publicInstance.setFeatureSelected();
    });
  }

  setFeatureSelected() {
    this.setState({ device: Geofence.get(GeofenceOverlay.getSelectedId()) });
  }

  render() {
    const { device } = this.state;
    return (
      <MinimizeContextProvider>
        <Paper elevation={3} id={"olMapGeofencePopup"}>
          {device && device?.id ? (
            <React.Fragment>
              <DataDisplayHeader device={device} />
              {
                <React.Fragment>
                  <DataDisplayButtonGroup device={device} />
                  <GeofenceInfo device={device} />
                </React.Fragment>
              }
            </React.Fragment>
          ) : null}
        </Paper>
      </MinimizeContextProvider>
    );
  }
}
