import React, { useState, useContext, useEffect } from "react";

// External
import { useFormikContext } from "formik";
import _ from "lodash";

// Material-UI
import { makeStyles, Grid, Button, MenuItem } from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import PanToolIcon from "@material-ui/icons/PanTool";
import OpenWithIcon from "@material-ui/icons/OpenWith";
import CloseIcon from "@material-ui/icons/Close";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import Typography from "@material-ui/core/Typography";

// Internal
import Language from "sccLanguage";
import Device from "sccDevice";
import Utils from "sccUtils";
import UserSetting from "sccUserSetting";
import GeofenceOverlay from "sccGeofenceOverlay";
import OlMap from "sccOlMapNew";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import CustomListItemCollapse from "@Components/CustomListItemCollapse";
import CustomSwitch from "@Components/CustomSwitch";
import CustomSwitchArea from "@Components/CustomSwitchArea";
import CustomAssetWrapper from "@Components/CustomAssetWrapper";
import CustomTextField from "@Components/CustomTextField";
import { CustomAssetSelectorContext } from "@Components/CustomAssetWrapper/CustomAssetSelectorContext";
import CustomDropdown from "@Components/CustomDropdown/CustomDropdown";
import CustomTooltip from "@Components/CustomTooltip";
import CustomInputAdornment from "@Components/CustomInputAdornment";
import CustomDialog from "@Components/CustomDialog/index.js";
import { SelectedGeofenceContext } from "../components/context/SelectedGeofenceContext";
import GeofenceSyncDevicesList from "./GeofenceSyncDevicesList";
import { moduleForm } from "@Styles/ModuleForm";

const useStyles = makeStyles((theme) => ({
  ...moduleForm(theme),
  unitsLabel: {
    padding: "6px 8px",
    backgroundColor: theme.palette.colors.gray.dark,
    border: `1px solid ${theme.palette.colors.gray.dark}`,
    fontSize: "0.9rem",
  },
  editGrid: {
    display: "flex",
    alignItems: "center",
  },
  reshapeBtn: {
    cursor: "pointer",
    border: `1px solid ${theme.palette.colors.gray.dark}`,
    color: theme.palette.colors.black.main,
    width: 120,
    marginTop: -7,
    marginRight: 5,
    "&:hover": {
      border: `1px solid ${theme.palette.colors.gray.main}`,
    },
  },
  reshapeEndBtn: {
    cursor: "pointer",
    border: `1px solid ${theme.palette.colors.red.dark}`,
    width: 120,
    marginTop: -7,
    backgroundColor: theme.palette.colors.red.main,
    color: theme.palette.colors.white.main,
    "&:hover": {
      border: `1px solid ${theme.palette.colors.red.main}`,
      backgroundColor: theme.palette.colors.red.dark,
    },
  },
  reshapeBtnPushed: {
    cursor: "pointer",
    border: `1px solid ${theme.palette.colors.gray.dark}`,
    backgroundColor: theme.palette.colors.primary.main,
    color: theme.palette.colors.white.main,
    width: 120,
    marginTop: -7,
    marginRight: 5,
    "&:hover": {
      border: `1px solid ${theme.palette.colors.gray.main}`,
      backgroundColor: theme.palette.colors.primary.dark,
    },
  },
  reshapeLabel: {
    textRransform: "uppercase",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginLeft: 4,
    lineHeight: 1.5,
  },
  reshapeBtnPushedLabel: {
    textRransform: "uppercase",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginLeft: 4,
    lineHeight: 1.5,
    color: theme.palette.colors.white.main,
  },
  locateIcon: {
    fontSize: 16,
    paddingLeft: 4,
    cursor: "pointer",
  },
  helpMessage: {
    backgroundColor: theme.palette.colors.primary.light,
    color: theme.palette.colors.white.main,
    "& p": {
      fontSize: 12,
      fontWeight: 700,
      lineHeight: 0.2,
    },
  },
  noDisplay: {
    display: "none",
  },
}));

const MW_ADDEDIT_MODE = process.env.REACT_APP_MW_ADDEDIT_MODE;

export default function GeofenceForm() {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const [assetSelectorState] = useContext(CustomAssetSelectorContext);
  const { geoState, setModuleEditMode } = useContext(SelectedGeofenceContext);

  const formik = useFormikContext();
  const moduleItemData = formik.values;

  const [triggersCount, setTriggersCount] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newShape, setNewShape] = useState("");
  const [oldShape, setOldShape] = useState("");
  const [featureCreatedOrUpdated, setfeatureCreatedOrUpdated] =
    useState("false");
  const [minSpeed, setMinSpeed] = useState(null);
  const [maxSpeed, setMaxSpeed] = useState(null);

  const allSyncDevices = Device.getSyncDevices();

  //to be used in add edit
  const handleFormInput = formik.handleChange;
  const handleFormBlur = formik.handleBlur;
  const geofenceShapeOptions = [
    { value: "polygon", title: "Polygon" },
    { value: "circle", title: "Circle" },
    { value: "path", title: "Path" },
    { value: "rectangle", title: "Rectangle" },
  ];

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleChangeShape = (newShape) => {
    handleFormInput(newShape);
    setOldShape(moduleItemData.shape);
    setNewShape(newShape?.target?.value);
    if (
      !_.isUndefined(moduleItemData.coordinates) &&
      moduleItemData.coordinates.length > 1
    ) {
      handleDialogOpen();
    } else {
      GeofenceOverlay.finishEditing();
      // background becomes dark
      setModuleEditMode("start");
      GeofenceOverlay.startDrawing(
        newShape?.target?.value,
        handleDrawingChange
      );
    }
  };

  const handleDrawingChange = (feature) => {
    GeofenceOverlay.setDrawFeature(feature);
    setfeatureCreatedOrUpdated(true);
    var drawingOutput = GeofenceOverlay.getDrawingCoordinates();
    if (!_.isNull(drawingOutput)) {
      formik.setFieldValue("coordinates", drawingOutput.coordinates);
      if (moduleItemData.shape !== "path") {
        formik.setFieldValue("width", drawingOutput.width);
      }
    }
  };

  function handleFormInputSpeed(e) {
    handleFormBlur(e);

    const field = e.target.name;
    const value = e.target.value;

    if (field === "min_speed_displayed") {
      setMinSpeed(value);
      formik.setFieldValue(
        "min_speed",
        Utils.transformSpeedToKph(value, UserSetting.get("speed_format"))
      );
      formik.setFieldValue("min_speed_displayed", value);
    } else if (field === "max_speed_displayed") {
      setMaxSpeed(value);
      formik.setFieldValue(
        "max_speed",
        Utils.transformSpeedToKph(value, UserSetting.get("speed_format"))
      );
      formik.setFieldValue("max_speed_displayed", value);
    }
  }

  const modifyGeofenceChanged = (id, type) => {
    GeofenceOverlay.endAllModifications();
    switch (type) {
      case "reshape":
        OlMap.editMode = "reshapeMap";
        // keep geofence feature selected
        if (id) {
          GeofenceOverlay.selectFeatureById(id, true);
        }

        // display dark background
        setModuleEditMode("reshapeMap");
        GeofenceOverlay.startModifying(id, handleDrawingChange);
        moduleItemData.reshape = true;
        GeofenceOverlay.showEndBtn = true;
        GeofenceOverlay.showDragMsg = false;
        break;
      case "drag":
        OlMap.editMode = "dragMap";
        // display dark background
        setModuleEditMode("dragMap");
        GeofenceOverlay.startTranslating(id, handleDrawingChange);
        moduleItemData.reshape = false;
        GeofenceOverlay.showEndBtn = true;
        GeofenceOverlay.showDragMsg = true;
        break;
      case "end":
        OlMap.editMode = "end";
        setModuleEditMode("end");
        GeofenceOverlay.endAllModifications();
        GeofenceOverlay.setBlockSelection(false);
        GeofenceOverlay.deselectFeature();
        moduleItemData.reshape = false;
        GeofenceOverlay.resetEndReshape();
        break;
      default:
        throw new Error("Wrong geofence modification type provided: " + type);
    }
  };

  const newGeofence = {
    title: "",
    note: "",
    shape: "",
    inclusive: false,
    active: true,
    min_speed: null,
    min_speed_displayed: null,
    max_speed: null,
    max_speed_displayed: null,
    width: null,
    approved: true,
    coordinates: [
      {
        longitude: null,
        latitude: null,
      },
    ],
    triggers: {
      devices: [],
      groups: [],
    },
    sync: {
      devices: [],
      groups: [],
    },
  };

  const isEditGeo = mwState.inputDataHasChanged(
    newGeofence,
    mwState.moduleItemData
  );

  const locateToCurrentGeo = () => {
    const geofenceId = mwState.moduleItemData.id;

    if (geofenceId) {
      GeofenceOverlay.centerAndZoomTo(geofenceId);
    }
  };

  const displayHelpMessage = () => {
    switch (geoState.moduleEditMode) {
      case "reshapeMap":
        return Language.translate(
          "Please click on the geofence circumference to reshape your geofence"
        );
      case "dragMap":
        return Language.translate(
          "Please click on the geofence and drag to desired location"
        );
      default:
        return "";
    }
  };

  const okAction = () => {
    formik.setFieldValue("coordinates", [
      {
        longitude: null,
        latitude: null,
      },
    ]);
    formik.setFieldValue("width", null);
    GeofenceOverlay.resetEndReshape();
    GeofenceOverlay.finishEditing();
    GeofenceOverlay.startDrawing(newShape, handleDrawingChange);
    setDialogOpen(false);
  };

  const cancelAction = () => {
    moduleItemData.shape = oldShape;
    formik.setFieldValue("width", moduleItemData.width);
    setDialogOpen(false);
  };

  //used to check the sync devices
  const [checkedDevices, setCheckedDevices] = useState([]);
  useEffect(() => {
    if (!_.isUndefined(moduleItemData.sync)) {
      setCheckedDevices(Device.getAvaiableDevices(moduleItemData.sync.devices));
    }
  }, [moduleItemData]);

  const handleToggle = (id) => {
    const currentIndex = checkedDevices.indexOf(id);
    const newChecked = [...checkedDevices];
    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedDevices(newChecked);
    formik.setFieldValue("sync.devices", newChecked);
  };

  useEffect(() => {
    if (mwState.wrapperDisplayMode === MW_ADDEDIT_MODE) {
      mwState.moduleItemData.min_speed_displayed = Utils.transformSpeedFromKph(
        mwState.moduleItemData.min_speed,
        UserSetting.get("speed_format")
      );
      mwState.moduleItemData.max_speed_displayed = Utils.transformSpeedFromKph(
        mwState.moduleItemData.max_speed,
        UserSetting.get("speed_format")
      );
      setMwState((p) => ({
        ...p,
        moduleItemData: { ...newGeofence, ...mwState.moduleItemData },
        moduleItemDataOriginal: { ...newGeofence, ...mwState.moduleItemData },
      }));
      setMinSpeed(
        Utils.transformSpeedFromKph(
          mwState.moduleItemData.min_speed,
          UserSetting.get("speed_format")
        )
      );
      setMaxSpeed(
        Utils.transformSpeedFromKph(
          mwState.moduleItemData.max_speed,
          UserSetting.get("speed_format")
        )
      );
    }
  }, [mwState.wrapperDisplayMode]);

  const classes = useStyles();

  useEffect(() => {
    if (assetSelectorState.selection["sync"]) {
      formik.setFieldValue("sync", assetSelectorState.selection["sync"]);
    }
    if (assetSelectorState.selection["triggers"]) {
      
      const triggers = assetSelectorState.selection["triggers"];

      // delete null/undefined
      triggers.groups = _.compact(triggers.groups);

      setTriggersCount(Device.getAvaiableDevices(assetSelectorState.selection["triggers"].devices).length);
      formik.setFieldValue(
        "triggers",
        triggers
      );
    }
  }, [assetSelectorState.selection]);

  useEffect(() => {
    const GeofenceOverlay = require("sccGeofenceOverlay").default;
    const feature = GeofenceOverlay.getDrawFeature();
    if (feature) {
      feature.setStyle(
        GeofenceOverlay.getStyleGivenInclusiveActiveFlags(
          moduleItemData.inclusive,
          moduleItemData.active,
          false
        )
      );
    }
    OlMap.inclusiveActiveStatesFromGeofenceFormComponent = {
      inclusive: moduleItemData.inclusive,
      active: moduleItemData.active,
    };
  }, [moduleItemData.inclusive, moduleItemData.active]);

  useEffect(() => {
    if (featureCreatedOrUpdated) {
      const feature = GeofenceOverlay.getDrawFeature();
      if (feature) {
        const style = GeofenceOverlay.getStyleGivenInclusiveActiveFlags(
          moduleItemData.inclusive,
          moduleItemData.active,
          false
        );
        feature.setStyle(style);
      }
    }
    setfeatureCreatedOrUpdated(false);
  }, [featureCreatedOrUpdated]);

  return (
    <React.Fragment>
      <div className={classes.formContainer}>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={6} className="switchWrap">
            <CustomSwitchArea
              name="inclusive"
              label={`${Language.translate("Area Type")}`}
              label2={
                moduleItemData.inclusive
                  ? Language.translate("inclusive").toUpperCase()
                  : Language.translate("exclusive").toUpperCase()
              }
              value={Boolean(moduleItemData.inclusive)}
              onChange={handleFormInput}
              checked={Boolean(moduleItemData.inclusive)}
              color="primary"
              labelPlacement="top"
            />
            <CustomSwitch
              name="active"
              label={`${Language.translate("Status")}`}
              label2={
                moduleItemData.active
                  ? Language.translate("active").toUpperCase()
                  : Language.translate("inactive").toUpperCase()
              }
              value={Boolean(moduleItemData.active)}
              onChange={handleFormInput}
              checked={Boolean(moduleItemData.active)}
              color="primary"
              labelPlacement="top"
            />
          </Grid>
          <Grid item xs={12}>
            <p className="pullRight">
              {"*" + Language.translate("Required fields")}
            </p>
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              label={`${Language.translate("Title")}*`}
              value={String(moduleItemData.title)}
              name="title"
              onChange={handleFormInput}
              onBlur={handleFormBlur}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              label={`${Language.translate("Annotation")}*`}
              value={String(moduleItemData.note)}
              name="note"
              onChange={handleFormInput}
              onBlur={handleFormBlur}
              error={formik.touched.note && Boolean(formik.errors.note)}
              helperText={formik.touched.note && formik.errors.note}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <CustomTextField
                  label={Language.translate("Min Speed")}
                  value={minSpeed}
                  onChange={handleFormInputSpeed}
                  onBlur={handleFormInputSpeed}
                  name="min_speed_displayed"
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <CustomInputAdornment position="end">
                        <CustomTooltip
                          placement="top"
                          title={
                            <React.Fragment>
                              <Typography>
                                <b>{Language.translate("Min Speed")}</b>
                                <br />
                                <span>
                                  {Utils.transformSpeedFromKph(
                                    moduleItemData.min_speed,
                                    "KPH"
                                  )}{" "}
                                  <b>{Language.translate("KPH")}</b>
                                </span>
                                <br />
                                <span>
                                  {Utils.transformSpeedFromKph(
                                    moduleItemData.min_speed,
                                    "MPH"
                                  )}{" "}
                                  <b>{Language.translate("MPH")}</b>
                                </span>
                                <br />
                                <span>
                                  {Utils.transformSpeedFromKph(
                                    moduleItemData.min_speed,
                                    "KNOTS"
                                  )}{" "}
                                  <b>{Language.translate(" KNOTS")}</b>
                                </span>
                              </Typography>
                            </React.Fragment>
                          }
                        >
                          <span className={classes.unitsLabel}>
                            {UserSetting.get("speed_format")}
                          </span>
                        </CustomTooltip>
                      </CustomInputAdornment>
                    ),
                  }}
                  error={
                    formik.touched.min_speed_displayed &&
                    Boolean(formik.errors.min_speed_displayed)
                  }
                  helperText={
                    formik.touched.min_speed_displayed &&
                    formik.errors.min_speed_displayed
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  label={Language.translate("Max Speed")}
                  value={maxSpeed}
                  onChange={handleFormInputSpeed}
                  onBlur={handleFormInputSpeed}
                  name="max_speed_displayed"
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <CustomInputAdornment position="end">
                        <CustomTooltip
                          placement="top"
                          title={
                            <React.Fragment>
                              <Typography>
                                <b>{Language.translate("Max Speed")}</b>
                                <br />
                                <span>
                                  {Utils.transformSpeedFromKph(
                                    moduleItemData.max_speed,
                                    "KPH"
                                  )}{" "}
                                  <b>{Language.translate("KPH")}</b>
                                </span>
                                <br />
                                <span>
                                  {Utils.transformSpeedFromKph(
                                    moduleItemData.max_speed,
                                    "MPH"
                                  )}{" "}
                                  <b>{Language.translate("MPH")}</b>
                                </span>
                                <br />
                                <span>
                                  {Utils.transformSpeedFromKph(
                                    moduleItemData.max_speed,
                                    "KNOTS"
                                  )}{" "}
                                  <b>{Language.translate(" KNOTS")}</b>
                                </span>
                              </Typography>
                            </React.Fragment>
                          }
                        >
                          <span className={classes.unitsLabel}>
                            {UserSetting.get("speed_format")}
                          </span>
                        </CustomTooltip>
                      </CustomInputAdornment>
                    ),
                  }}
                  error={
                    formik.touched.max_speed_displayed &&
                    Boolean(formik.errors.max_speed_displayed)
                  }
                  helperText={
                    formik.touched.max_speed_displayed &&
                    formik.errors.max_speed_displayed
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <CustomDropdown
                  label={`${Language.translate("Shape")}*`}
                  name="shape"
                  className="Shape"
                  value={moduleItemData.shape || ""}
                  onChange={handleChangeShape}
                  onBlur={handleFormBlur}
                  disabled={isEditGeo}
                  error={formik.touched.shape && Boolean(formik.errors.shape)}
                  helperText={formik.touched.shape && formik.errors.shape}
                >
                  {geofenceShapeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <p>{Language.translate(option.title)}</p>
                    </MenuItem>
                  ))}
                </CustomDropdown>
              </Grid>
            </Grid>
            <Grid item xs={6} md={3}>
              {moduleItemData.shape === "circle" && (
                <CustomTextField
                  type="number"
                  label={`${Language.translate("Radius")}*`}
                  name="radius"
                  value={moduleItemData.width}
                  onChange={handleFormInput}
                  disabled={true}
                  InputProps={{
                    endAdornment: (
                      <CustomInputAdornment position="end">
                        <CustomTooltip
                          placement="top"
                          title={
                            <React.Fragment>
                              <Typography>
                                <b>{Language.translate("Radius")}</b>
                                <br />
                                <span>
                                  {Utils.transformMetresToKm(
                                    moduleItemData.width,
                                    "Kilometres"
                                  )}{" "}
                                  <b>{Language.translate("Kilometers")}</b>
                                </span>
                                <br />
                                <span>
                                  {moduleItemData.width}{" "}
                                  <b>{Language.translate("Meters")}</b>
                                </span>
                                <br />
                                <span>
                                  {Utils.transformMetresToKm(
                                    moduleItemData.width,
                                    "Feet"
                                  )}{" "}
                                  <b>{Language.translate("Feet")}</b>
                                </span>
                              </Typography>
                            </React.Fragment>
                          }
                        >
                          <span className={classes.unitsLabel}>
                            {Language.translate("Meters").toUpperCase()}
                          </span>
                        </CustomTooltip>
                      </CustomInputAdornment>
                    ),
                  }}
                  error={formik.touched.radius && Boolean(formik.errors.radius)}
                  helperText={formik.touched.radius && formik.errors.radius}
                />
              )}
              {moduleItemData.shape === "path" && (
                <CustomTextField
                  type="number"
                  label={`${Language.translate("Width")}*`}
                  name="width"
                  value={moduleItemData.width}
                  onChange={handleFormInput}
                  InputProps={{
                    endAdornment: (
                      <CustomInputAdornment position="end">
                        <CustomTooltip
                          placement="top"
                          title={
                            <React.Fragment>
                              <Typography>
                                <b>{Language.translate("Width")}</b>
                                <br />
                                <span>
                                  {Utils.transformMetresToKm(
                                    moduleItemData.width,
                                    "Kilometres"
                                  )}{" "}
                                  <b>{Language.translate("Kilometers")}</b>
                                </span>
                                <br />
                                <span>
                                  {moduleItemData.width}{" "}
                                  <b>{Language.translate("Meters")}</b>
                                </span>
                                <br />
                                <span>
                                  {Utils.transformMetresToKm(
                                    moduleItemData.width,
                                    "Feet"
                                  )}{" "}
                                  <b>{Language.translate("Feet")}</b>
                                </span>
                              </Typography>
                            </React.Fragment>
                          }
                        >
                          <span className={classes.unitsLabel}>
                            {Language.translate("Meters").toUpperCase()}
                          </span>
                        </CustomTooltip>
                      </CustomInputAdornment>
                    ),
                  }}
                  error={formik.touched.width && Boolean(formik.errors.width)}
                  helperText={formik.touched.width && formik.errors.width}
                />
              )}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            className={
              ["reshapeMap", "dragMap"].includes(geoState.moduleEditMode)
                ? classes.helpMessage
                : classes.noDisplay
            }
          >
            <p>{displayHelpMessage()}</p>
          </Grid>
          {!_.isUndefined(moduleItemData.coordinates) &&
            moduleItemData.coordinates.length > 1 && (
              <Grid item container spacing={1} direction="row">
                <Grid item className={classes.editGrid}>
                  <FormLabel className="MuiInputLabel-root">
                    <span className={classes.marker}>
                      {Language.translate("Edit Shape")}
                    </span>
                    {moduleItemData.id && (
                      <CustomTooltip
                        placement="top"
                        title={<p>{Language.translate("locate")}</p>}
                      >
                        <MyLocationIcon
                          className={classes.locateIcon}
                          onClick={() => locateToCurrentGeo()}
                        />
                      </CustomTooltip>
                    )}
                  </FormLabel>
                </Grid>
                <Grid item container>
                  {["rectangle"].indexOf(moduleItemData.shape) === -1 && (
                    <Grid item>
                      <Button
                        size="medium"
                        color="inherit"
                        className={
                          ["reshapeMap"].includes(geoState.moduleEditMode)
                            ? classes.reshapeBtnPushed
                            : classes.reshapeBtn
                        }
                        onClick={() =>
                          modifyGeofenceChanged(
                            moduleItemData.id || 0,
                            "reshape"
                          )
                        }
                      >
                        <OpenWithIcon />
                        <span
                          className={
                            ["reshapeMap"].includes(geoState.moduleEditMode)
                              ? classes.reshapeBtnPushedLabel
                              : classes.reshapeLabel
                          }
                        >
                          {Language.translate("Re-shape")}
                        </span>
                      </Button>
                    </Grid>
                  )}
                  <Grid item>
                    <Button
                      size="medium"
                      color="inherit"
                      className={
                        ["dragMap"].includes(geoState.moduleEditMode)
                          ? classes.reshapeBtnPushed
                          : classes.reshapeBtn
                      }
                      onClick={() =>
                        modifyGeofenceChanged(moduleItemData.id || 0, "drag")
                      }
                    >
                      <PanToolIcon />
                      <span
                        className={
                          ["dragMap"].includes(geoState.moduleEditMode)
                            ? classes.reshapeBtnPushedLabel
                            : classes.reshapeLabel
                        }
                      >
                        {Language.translate("Drag")}
                      </span>
                    </Button>
                  </Grid>

                  {["reshapeMap", "dragMap"].includes(
                    geoState.moduleEditMode
                  ) && (
                    <Grid item>
                      <Button
                        size="medium"
                        color="inherit"
                        className={classes.reshapeEndBtn}
                        onClick={() =>
                          modifyGeofenceChanged(moduleItemData.id || 0, "end")
                        }
                      >
                        <CloseIcon />
                        <span
                          htmlFor="radGeofenceDrag"
                          className={classes.reshapeLabel}
                        >
                          {Language.translate("Finish")}
                        </span>
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CustomListItemCollapse
                title={Language.translate("Triggers").toUpperCase()}
                color="primary"
                collapse={true}
                count={triggersCount}
              >
                <CustomAssetWrapper
                  editMode={true}
                  selection={
                    mwState.moduleItemData.triggers || {
                      groups: [],
                      devices: [],
                    }
                  }
                  name="triggers"
                  openMain={true}
                  showDeviceSearchField={false}
                />
              </CustomListItemCollapse>
            </Grid>
            <Grid item xs={6}>
              <GeofenceSyncDevicesList
                checkedDevices={checkedDevices}
                allDevices={allSyncDevices}
                onDeviceClick={handleToggle}
              />
            </Grid>
          </Grid>

          <CustomDialog
            open={dialogOpen}
            onCancelAction={cancelAction}
            onOkAction={okAction}
            text={Language.translate(
              "Your current drawing will be lost. Do you want to continue?"
            )}
          />
        </Grid>
      </div>
    </React.Fragment>
  );
}
