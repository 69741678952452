import React, { useState, useContext, useEffect } from "react";

// External
import _ from "lodash";
import { useFormikContext } from "formik";

// Material-UI
import { makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import RssFeedIcon from "@material-ui/icons/RssFeed";

// Components
import CustomButton from "@Components/CustomButton";
import CustomDialog from "@Components/CustomDialog";
import CustomIcon from "@Components/CustomIcon";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

// Styles
import { moduleForm } from "@Styles/ModuleForm";

// Internal
import { AppContext } from "../../../AppContext";
import AssetsFormInfo from "./AssetsFormInfo";
import AssetsFormSettings from "./AssetsFormSettings";
import AssetsFormSettingsHawkeye from "./AssetsFormSettingsHawkeye";
import AssetsFormOrionSettings from "./AssetsFormOrionSettings";
import Device from "sccDevice";
import Language from "sccLanguage";
import Profile from "sccProfile";
import Utils from "sccUtils";

const useStyles = makeStyles((theme) => ({
  ...moduleForm(theme),
  formContainer: {
    ...moduleForm(theme).formContainer,
    "& > h1": {
      fontSize: "1.8rem",
    },
    "& .settingsBtnWrapper": {
      float: "left",
      width: "100%",
      "& .settingsBtnFactoryReset": {
        float: "left",
        margin: "15px 0",
      },
      "& .settingsBtnPollSettings": {
        float: "right",
        margin: "15px 0",
      },
    },
  },
}));

export default function DeviceForm() {
  const formik = useFormikContext();
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const [appState] = useContext(AppContext);
  const [deviceTypeFeatures, setDeviceTypeFeatures] = useState({});

  //to be used in add edit
  const moduleItemData = formik.values;
  const moduleItemDataOriginal = mwState.moduleItemDataOriginal;
  const [value, setValue] = useState(0);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);
  const [password, setPassword] = useState();
  const pollCommands = mwState.pollCommands;

  const handleconfirmDialogOpen = () => {
    setConfirmDialogOpen(true);
  };
  function okAction() {
    setConfirmDialogOpen(false);
    setLoginDialogOpen(true);
  }
  function cancelAction() {
    setConfirmDialogOpen(false);
    setLoginDialogOpen(false);
  }

  const checkIfConsignment = function (device, isSettings) {
    if (_.isNull(device) || _.isUndefined(device)) return;
    const deviceType = deviceTypeFeatures[moduleItemData.type_id];
    if (
      deviceType.title === "Container CCU" ||
      deviceType.title === "Container TAG" ||
      (deviceType.title === "Shadow" &&
        deviceType.modes[device.mode]?.title === "Consignment" &&
        isSettings === false)
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (!_.isEmpty(moduleItemData)) {
      if (!_.isEmpty(Device._deviceType)) {
        setDeviceTypeFeatures(Device._deviceType);
      }
    }
    if (moduleItemData.type === "NORTAC Orion") {
      setMwState((p) => ({
        ...p,
        moduleItemData: {
          ...p.moduleItemData,
          sendSettings: JSON.stringify(
            mwState.maximizePollSettingsForOrion(
              JSON.parse(moduleItemData.settings)
            )
          ),
          settingsVal: mwState.maximizePollSettingsForOrion(
            JSON.parse(moduleItemData.settings)
          ),
        },
        moduleItemDataOriginal: {
          ...p.moduleItemData,
          sendSettings: JSON.stringify(
            mwState.maximizePollSettingsForOrion(
              JSON.parse(moduleItemData.settings)
            )
          ),
          settingsVal: mwState.maximizePollSettingsForOrion(
            JSON.parse(moduleItemData.settings)
          ),
        },
      }));
    }
  }, [moduleItemData.type_id]);

  useEffect(() => {
    setMwState((p) => ({
      ...p,
      routeUrl: "/api/v1/device", //need to specifically set this to device
    }));
  }, []);

  // clear formik form value when componentWillUnmount, this avoid bug when user open another form directly from this page
  useEffect(() => {
    return () => {
      formik.resetForm({
        values: {},
      });
    };
  }, []);

  function handleTabs(e, val) {
    setValue(val);
  }

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = (e) => {
    const username = Profile.get().username;
    const data = { username, password };
    const url = Utils.apiUrlPrefix + "/auth/login";

    return fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.error) {
          appState.displaySnackbarMessage({
            message: "Clear Data Denied",
            variant: "error",
          });
          setLoginDialogOpen(false);
        }
        if (data.token) {
          pollCommands(moduleItemData, "clear_data");
          // close login Dialog
          setLoginDialogOpen(false);
        }
      })
      .catch(function (err) {
        console.log("Error in Catch: ", err);
      });
  };

  function handleSpecialSaveValidation(formik) {
    if (
      formik.errors.settingsVal &&
      Object.values(formik.errors.settingsVal).length > 0
    ) {
      setValue(1);
    } else if (Object.values(formik.errors).length > 0) {
      setValue(0);
    }
    return true;
  }

  const handlePollCommands = (e) => {
    pollCommands(moduleItemData, "poll_settings");
  };

  const classes = useStyles();

  if (Device.getHermesDevices().includes(moduleItemData.type)) {
    return (
      <div className={classes.formContainer}>
        <h1>{moduleItemDataOriginal.name}</h1>
        <Tabs
          value={value}
          onChange={handleTabs}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab
            label={Language.translate("Info")}
            icon={
              <CustomIcon
                fontSize="small"
                style={{ fontSize: 9 }}
                id="icon-info-circle"
              />
            }
          />
        </Tabs>
        {value === 0 && (
          <AssetsFormInfo
            handleSpecialSaveValidation={handleSpecialSaveValidation}
            deviceType={deviceTypeFeatures[moduleItemData.type_id]}
          />
        )}
      </div>
    );
  } else {
    return (
      <div className={classes.formContainer}>
        <h1>{moduleItemDataOriginal.name}</h1>

        <Tabs
          value={value}
          onChange={handleTabs}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab
            label={Language.translate("Info")}
            icon={
              <CustomIcon
                fontSize="small"
                style={{ fontSize: 9 }}
                id="icon-info-circle"
              />
            }
          />
          <Tab
            label={Language.translate("Settings")}
            style={{ fontSize: 9 }}
            icon={
              <CustomIcon
                fontSize="small"
                style={{ fontSize: 9 }}
                id="icon-cog"
              />
            }
          />
        </Tabs>
        {value === 0 && (
          <AssetsFormInfo
            handleSpecialSaveValidation={handleSpecialSaveValidation}
            deviceType={deviceTypeFeatures[moduleItemData.type_id]}
          />
        )}
        {value === 1 && (
          <React.Fragment>
            <CustomDialog
              type="logout"
              open={confirmDialogOpen}
              onCancelAction={cancelAction}
              onOkAction={okAction}
              text={
                "Are you sure you want to remove all data from the device? If applicable, after device reset any applied encryption keys will need to be reconfigured between the device and the platform."
              }
            />
            <CustomDialog
              type="logout"
              open={loginDialogOpen}
              onCancelAction={cancelAction}
              onOkAction={handleLogin}
              text={Language.translate(
                "Please enter your login password to clear all device data."
              )}
              children={
                <TextField
                  autoFocus
                  margin="dense"
                  id="Factory_Reset_Password"
                  type="password"
                  onChange={handlePassword}
                  fullWidth
                />
              }
            />
            <div className="settingsBtnWrapper">
              {deviceTypeFeatures[moduleItemData.type_id] &&
                deviceTypeFeatures[moduleItemData.type_id].title === "Wave" &&
                deviceTypeFeatures[moduleItemData.type_id].modes[
                  moduleItemData.mode
                ].title === "Standalone" && (
                  <CustomButton
                    className="settingsBtnFactoryReset"
                    onClick={handleconfirmDialogOpen}
                    size="medium"
                    color="delete"
                    variant="contained"
                    disabled={!Device.showClearData(moduleItemData)}
                    startIcon={
                      Device.showClearData(moduleItemData) ? (
                        <DeleteIcon />
                      ) : (
                        <CircularProgress color="secondary" />
                      )
                    }
                  >
                    {Device.showClearData(moduleItemData)
                      ? Language.translate("Factory Reset")
                      : Language.translate("Resetting Device")}
                  </CustomButton>
                )}
              {deviceTypeFeatures[moduleItemData.type_id] &&
                deviceTypeFeatures[moduleItemData.type_id].title !==
                  "Hawkeye 5500" && (
                  <CustomButton
                    className="settingsBtnPollSettings"
                    onClick={(e) => {
                      e.target.blur();
                      handlePollCommands();
                    }}
                    size="medium"
                    color="command"
                    variant="contained"
                    startIcon={<RssFeedIcon />}
                  >
                    {Language.translate("Poll Settings")}
                  </CustomButton>
                )}
            </div>

            {deviceTypeFeatures[moduleItemData.type_id] &&
              deviceTypeFeatures[moduleItemData.type_id].title ===
                "Hawkeye 5500" && (
                <AssetsFormSettingsHawkeye
                  handleSpecialSaveValidation={handleSpecialSaveValidation}
                  moduleItemData={moduleItemData}
                  deviceType={deviceTypeFeatures[moduleItemData.type_id]}
                />
              )}

            {deviceTypeFeatures[moduleItemData.type_id] &&
              deviceTypeFeatures[moduleItemData.type_id].title ===
                "NORTAC Orion" && (
                <AssetsFormOrionSettings
                  handleSpecialSaveValidation={handleSpecialSaveValidation}
                  moduleItemData={moduleItemData}
                />
              )}
            {deviceTypeFeatures[moduleItemData.type_id] &&
              !checkIfConsignment(moduleItemData, true) &&
              deviceTypeFeatures[moduleItemData.type_id].title !==
                "NORTAC Orion" &&
              deviceTypeFeatures[moduleItemData.type_id].title !==
                "Hawkeye 5500" && (
                <AssetsFormSettings
                  handleSpecialSaveValidation={handleSpecialSaveValidation}
                  deviceType={deviceTypeFeatures[moduleItemData.type_id]}
                />
              )}
          </React.Fragment>
        )}
      </div>
    );
  }
}
