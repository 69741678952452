import React, { useContext } from "react";

// Material-UI
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

// Internal
import { MinimizeContext } from "../../context/MinimizeContext";
import { UserSettingContext } from "../../../../user_setting/context/UserSettingContext";
import Geofence from "sccGeofence";
import Utils from "sccUtils";
import Language from "sccLanguage";
import { moduleListingDetails } from "@Styles/ModuleListingDetails";

const useStyles = makeStyles((theme) => ({
  ...moduleListingDetails(theme),

  GeofenceInfo: {
    "& .MuiList-root": {
      padding: "0",

      "& .MuiListItem-root": {
        border: `1px solid ${theme.palette.colors.gray.main}`,
        padding: "0",
        marginTop: "-1px",

        "& .GeofenceInfoListItemText-first": {
          background: theme.palette.colors.gray.main,
          margin: "0",
          padding: "4px 8px",
          width: "55px",
          "& .MuiListItemText-primary": {
            fontSize: "0.9rem",
            overflow: "hidden",
            //"white-space": "nowrap",
            textOverflow: "ellipsis",
          },
        },

        "& .GeofenceInfoListItemIcon": {
          background: theme.palette.colors.gray.main,
          fontWeight: "bold",
          fontSize: "12px",
        },

        "& .GeofenceInfoListItemText-second": {
          margin: "0",
          textAlign: "right",
          padding: "4px 8px",
          width: "85px",

          "& .MuiListItemText-primary": {
            fontSize: "0.9rem",
            overflow: "hidden",
            //white-space: "nowrap",
            textOverflow: "ellipsis",
          },
        },

        "& .GeofenceInfoListItemText-second-radius": {
          margin: "0",
          textAlign: "right",
          padding: "4px 8px",
          width: "85px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          flexWrap: "nowrap",

          "& .MuiListItemText-primary": {
            fontSize: "0.9rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },

          "& .MuiListItemText-secondary": {
            paddingFLeft: "8px",
          },
        },
      },
    },

    "& .MuiTypography-body2": {
      fontSize: "0.9rem",
    },
  },
}));

export default function GeofenceInfo({ device }) {
  const { state } = useContext(MinimizeContext);
  const { userSettingData } = useContext(UserSettingContext);
  const deviceTypeFeatures = Geofence.get(device.id);

  const classes = useStyles();

  const speed_unit = userSettingData["speed_format"] || "N/A";

  return (
    <React.Fragment>
      {!state.minimize &&
      deviceTypeFeatures &&
      Object.keys(deviceTypeFeatures).length > 0 ? (
        <Grid item xs={12} md={12} className={classes.GeofenceInfo}>
          {device.note && (
            <List>
              <ListItem>
                <ListItemText
                  md={6}
                  className="GeofenceInfoListItemText-first"
                  primary={Language.translate("Annotation").toUpperCase()}
                />
                <ListItemText
                  md={6}
                  className="GeofenceInfoListItemText-second"
                  primary={device.note}
                />
              </ListItem>
            </List>
          )}
          <List>
            <ListItem>
              <ListItemText
                md={6}
                className="GeofenceInfoListItemText-first"
                primary={Language.translate("Status").toUpperCase()}
              />
              <ListItemText
                md={6}
                className="GeofenceInfoListItemText-second"
                primary={
                  device.active
                    ? Language.translate("Active")
                    : Language.translate("Inactive")
                }
              />
            </ListItem>
          </List>
          {device.shape?.length > 0 && (
            <List>
              <ListItem>
                <ListItemText
                  md={6}
                  className="GeofenceInfoListItemText-first"
                  primary={Language.translate("SHAPE")}
                />
                <ListItemText
                  md={6}
                  className="GeofenceInfoListItemText-second"
                  // captalize first letter
                  primary={
                    device.shape.charAt(0).toUpperCase() + device.shape.slice(1)
                  }
                />
              </ListItem>
            </List>
          )}
          {device.width && (
            <List>
              <ListItem>
                <ListItemText
                  md={6}
                  className="GeofenceInfoListItemText-first"
                  primary={Language.translate("RADIUS")}
                />
                <ListItemText
                  md={3}
                  className="GeofenceInfoListItemText-second-radius"
                  primary={device.width}
                  secondary={Language.translate("METERS")}
                />
              </ListItem>
            </List>
          )}
          {speed_unit && 
          (device.min_speed || device.max_speed) &&
          (
            <List>
              <ListItem>
                <ListItemText
                  md={6}
                  className="GeofenceInfoListItemText-first"
                  primary={Language.translate("SPEED UNIT")}
                />
                <ListItemText
                  md={6}
                  className="GeofenceInfoListItemText-second"
                  primary={speed_unit}
                />
              </ListItem>
            </List>
          )}

          {device.min_speed !== null && (
            <List>
              <ListItem>
                <ListItemText
                  md={6}
                  className="GeofenceInfoListItemText-first"
                  primary={Language.translate("MINIMUM SPEED")}
                />
                <ListItemText
                  md={6}
                  className="GeofenceInfoListItemText-second"
                  primary={Utils.transformSpeedFromKph(
                    device.min_speed,
                    speed_unit
                  )}
                />
              </ListItem>
            </List>
          )}
          {device.max_speed !== null && (
            <List>
              <ListItem>
                <ListItemText
                  md={6}
                  className="GeofenceInfoListItemText-first"
                  primary={Language.translate("MAXIMUM SPEED")}
                />
                <ListItemText
                  md={6}
                  className="GeofenceInfoListItemText-second"
                  primary={Utils.transformSpeedFromKph(
                    device.max_speed,
                    speed_unit
                  )}
                />
              </ListItem>
            </List>
          )}

          <List>
            <ListItem>
              <ListItemText
                md={6}
                className="GeofenceInfoListItemText-first"
                primary={Language.translate("TYPE")}
              />
              <ListItemText
                md={6}
                className="GeofenceInfoListItemText-second"
                primary={
                  device.inclusive
                    ? Language.translate("Inclusive")
                    : Language.translate("Exclusive")
                }
              />
            </ListItem>
          </List>
        </Grid>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}
