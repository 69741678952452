// External
import _ from "lodash";

// Internal
import Utils from "sccUtils";
import log from "loglevel";
import { default as UserSetting } from "sccUserSetting";

// use new translations file that have all Login/resertPassword tranlations
const loginTranslations = require("./LoginTranslations.js").loginTranslations;
/**
 * Language Class
 *
 * @class
 */

class LanguageModule {
  constructor() {
    this.moduleName = "language";

    /**
     * stores the language translation object
     */
    this._data = {};
    this._awsData = {};
    this._loginData = {};

    this._this = this;

    /**
     * stores mechanics related to the DB
     */
    this._languages = null;

    this._languageCode = null;
    this._languageCodeId = null;
  }

  init() {
    this.getLanguages();
    return this.getDataSource();
  }

  getLanguageCode() {
    var targetCodeID = UserSetting.get("language_id");
    if (this._languages != null) {
      if (targetCodeID != null && this._languages[targetCodeID] != null) {
        this._languageCode = this._languages[targetCodeID].code.toLowerCase();
        this._languageCodeId = targetCodeID;
      }
    }
  }

  translate() {
    //LanguageModule.prototype.translate = function(){
    var source = arguments[0];

    if (this._languageCode == null) {
      this.getLanguageCode();
    }

    var theString = source;
    if (
      this._data !== undefined &&
      this._data != null &&
      Object.keys(this._data).length !== 0
    ) {
      theString = this._data[tagMap[source]];
    }

    var theString2 = source;

    if (this._awsData !== undefined && this._awsData != null) {
      if (Object.keys(this._awsData).length > 0) {
        if (
          this._awsData[source] != null &&
          Object.keys(this._awsData[source]).length > 0 &&
          this._awsData[source][this._languageCode] !== "@@"
        ) {
          theString2 = this._awsData[source][this._languageCode];
        } else {
          this.translateViaAWS(source, this);
          if (this._awsData[source] == null) {
            this._awsData[source] = {};
            log.debug("Creating record for: " + source);
          }
          this._awsData[source][this._languageCode] = theString2;
        }
      } else {
        return source;
      }
    } else {
      return source;
    }

    // formatting the string.
    for (var i = 1; i < arguments.length; i++) {
      // "gm" = RegEx options for Global search (more than one instance)
      // and for Multiline search
      var regEx = new RegExp("\\{" + (i - 1) + "\\}", "gm");
      theString = theString.replace(regEx, arguments[i]);
    }

    // formatting the string2.
    for (i = 1; i < arguments.length; i++) {
      // "gm" = RegEx options for Global search (more than one instance)
      // and for Multiline search
      regEx = new RegExp("\\{" + (i - 1) + "\\}", "gm");
      theString2 = theString2.replace(regEx, arguments[i]);
    }

    return theString2;
  }

  //LanguageModule.prototype.translateLogin= function(){
  translateLogin() {
    var source = arguments[0];
    var languageCode = arguments[1];

    this.getLoginTranslations();

    log.debug("Translating " + source);
    if (this._loginData !== undefined && this._loginData != null) {
      var translationFound = this._loginData[source];
      //log.debug("Translating " + source + " into " + languageCode + " becomes " + JSON.stringify(this._loginData[source][languageCode]));
      if (translationFound[languageCode]) {
        return this._loginData[source][languageCode];
      } else {
        return "Ian, you still heck'd up.";
      }
    } else {
      return source;
    }
  }

  getLoginTranslations() {
    // Only read in the set once
    if (
      this._loginData !== undefined &&
      JSON.stringify(this._loginData) !== "{}"
    ) {
      return;
    }

    var lines = loginTranslations.split("\n");
    var headers = lines[0].split(",");

    var translationOutput = {};

    for (var lineIterator = 1; lineIterator < lines.length; lineIterator++) {
      var translationLineObject = {};
      var currentTranslationLine = lines[lineIterator].split(",");

      for (
        var translationIterator = 0;
        translationIterator < headers.length;
        translationIterator++
      ) {
        translationLineObject[headers[translationIterator]] =
          currentTranslationLine[translationIterator];
      }

      translationOutput[currentTranslationLine[0]] = translationLineObject;
    }

    this._loginData = translationOutput;

    log.debug(
      "Translation read in from file: " + JSON.stringify(this._loginData)
    );
  }
}

var tagMap = require("./dict.js").default;

LanguageModule.prototype.translateViaAWS = function () {
  var source = arguments[0];
  var $this = arguments[1];

  if ($this._languageCode == null) {
    $this.getLanguageCode();
  }

  if ($this._languages != null && $this._languageCode != null) {
    if (
      $this._languages != null &&
      $this._languages[$this._languageCodeId] != null
    ) {
      var options = {
        url:
          "translate" +
          Utils.apiUrlPrefix +
          "/translate/" +
          $this._languageCode +
          "/" +
          source,
        method: "GET",
        data: {},
      };

      return Utils.httpRequestHandler(options).then(function (
        response,
        _this = $this
      ) {
        if (_.isUndefined(response)) {
          return;
        }
        var languageData = response.data;
        log.debug("Language Data : ", languageData);

        log.debug(
          "Fetched translation of " +
            source +
            ": " +
            JSON.stringify(languageData)
        );

        if (_this._awsData[source] == null) {
          _this._awsData[source] = {};
          log.debug("Creating record for: " + source);
        }
        _this._awsData[source][_this._languageCode] = languageData;

        return languageData;
      });
    }
  }
};

LanguageModule.prototype.getDataSource = function () {
  var options = {
    url: "translate" + Utils.apiUrlPrefix + "/translate/datasource",
    method: "GET",
    data: {},
  };

  var $this = this;

  return Utils.httpRequestHandler(options).then(function (response) {
    log.debug("Language Data : ", response.data);
    $this._awsData = response.data;
    $this.Data = response.data;
    return $this._awsData;
  });
};
/**
 * loads user's language data from the DB
 */
LanguageModule.prototype.loadData = function () {
  var options = {
    url: Utils.apiUrlPrefix + "/language/data",
    method: "GET",
    data: {},
  };
  var $this = this;
  return Utils.httpRequestHandler(options).then(function (response) {
    $this._data = response.data.result;
    $this.Data = response.data.result;
    log.debug("Language Data : ", $this._data);
    return $this._data;
  });
};

LanguageModule.prototype.translateDefaultTerms = function () {
  var source = arguments[0];
  var result = source;

  switch (source) {
    case "Customer Admin":
    case "Advanced User":
    case "Contact User":
    case "Admin":
    case "End User":
    case "NATO":
    case "Default":
      result = this.translate(source);
      break;
    default:
      result = source;
  }

  return result;
};

/**
 * loads the list of all languages
 */
LanguageModule.prototype.getLanguages = function () {
  if (this._languages) return Promise.resolve(this._languages);
  var options = {
    url: Utils.apiUrlPrefix + "/language",
    method: "GET",
    data: {},
  };
  var $this = this;
  return Utils.httpRequestHandler(options).then(function (response) {
    $this._languages = response.data.result;
    return $this._languages;
  });
};

export default new LanguageModule();
