import React, { useState, useEffect } from "react";

// External
import _ from "lodash";
import clsx from "clsx";

// Material-UI
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import BatteryCharging60Icon from "@material-ui/icons/BatteryCharging60";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import OpacityIcon from "@material-ui/icons//Opacity";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import WhatshotIcon from "@material-ui/icons/Whatshot";

// Components
import CustomButton from "@Components/CustomButton";
import CustomIcon from "@Components/CustomIcon";
import CustomTooltip from "@Components/CustomTooltip";

// Styles
import { moduleListingDetails } from "@Styles/ModuleListingDetails";

// Internal
import Alert from "sccAlert";
import AlertMenu from "sccAlertMenu";
import Clock from "sccClock";
import Device from "sccDevice";
import Geofence from "sccGeofence";
import GuiUtils from "sccGuiUtils";
import Language from "sccLanguage";
import OlMap from "sccOlMapNew";
import Permission from "sccPermission";
import Utils from "sccUtils";
import User from "sccUser";
import UserSetting from "sccUserSetting";

const useStyles = makeStyles((theme) => ({
  ...moduleListingDetails(theme),
  speedGrid: {
    lineHeight: "25px",
    fontSize: "1rem",
  },

  speedGridMinMax: {
    lineHeight: "15px",
    backgroundColor: theme.palette.colors.gray.dark,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  infoTabs: {
    display: "flex",
    width: "100%",
    flexDirection: "column",

    "& div": {
      width: "100%",
    },
  },

  coordinate: {
    fontSize: "1rem",
  },

  fullWidth: {
    width: "100%",
  },

  resetListItem: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "8px !important",
    paddingTop: "4px !important",
  },

  alertMap: {
    width: "calc(100% - 16px)",
    margin: "2px 8px",
    height: "300px",
    border: `1px solid ${theme.palette.colors.gray.pure}`,
    display: "block",
  },

  alertMapHidden: {
    display: "none",
  },

  pendingCircle: {
    width: "15px !important",
    height: "15px !important",
    fontSize: "15px !important",
  },

  cargoDetailTabs: {
    display: "flex",
    flexDirection: "column",

    "& div": {
      width: "100%",
    },
  },

  cargoDetailTabsContainer: {
    width: "100%",
  },

  cargoTextSpan: {
    display: "inline-flex",
  },

  cargoText: {
    "& svg": {
      paddingTop: 2,
    },
  },

  cargoDanger2: {
    "& svg": {
      fill: theme.palette.colors.white.main,
      paddingTop: 2,
    },
    "& .itemTextFirst": {
      fontWeight: "bold",
      color: ` ${theme.palette.colors.white.main} !important`,
      backgroundColor: theme.palette.colors.red.dark,
    },

    "& .itemTextSecond": {
      fontWeight: "bold",
      color: `${theme.palette.colors.red.dark} !important`,
    },
  },

  textCenter: {
    textAlign: "center",
    borderRight: `1px solid ${theme.palette.colors.gray.dark}`,
    borderLeft: `1px solid ${theme.palette.colors.gray.dark}`,
  },

  texCenterDanger: {
    textAlign: "center",
    borderRight: `1px solid ${theme.palette.colors.gray.dark}`,
    borderLeft: `1px solid ${theme.palette.colors.gray.dark}`,
    backgroundColor: theme.palette.colors.red.dark,
    fontWeight: "bold",
    color: ` ${theme.palette.colors.white.main} !important`,
  },

  cargoDanger: {
    "& svg": {
      fill: theme.palette.colors.red.dark,
      paddingTop: 2,
    },
    "& .itemTextFirst": {
      color: `${theme.palette.colors.red.dark} !important`,
      backgroundColor: theme.palette.background.warning.main,
      borderColor: theme.palette.background.warning.light,
    },
    "& .itemTextSecond": {
      color: `${theme.palette.colors.red.dark} !important`,
    },
  },

  cargoRightDanger2: {
    fontWeight: "bold",
    color: `${theme.palette.colors.white.main} !important`,
    backgroundColor: theme.palette.colors.red.dark,
  },

  flexCenter: {
    display: "flex",
    alignItems: "center",
  },

  geofenceIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      fontSize: "10px !important",
    },
  },
}));

export default function AlertDetail(props) {
  const alert = props.data;
  const device = props.deviceInfo;
  const age = props.age;

  const deviceTypesCannotReset = [
    ...Device.getHermesDevices(),
    "LT3100",
     
  ];

  // get alert type
  const alertType = alert
    ? Alert.getAlertType(alert.alert_type_id)?.type
    : "Emergency";

  // used to display tab value
  const [value, setValue] = useState(0);
  const speed = Utils.transformSpeedFromKph(
    alert.info.trigger_speed,
    UserSetting.get().speed_format
  );

  // used to display map
  const [isShowMap, setIsShowMap] = useState(false);

  useEffect(() => {
    // Trigger to display AlertMap here
    if (isShowMap === true) {
      AlertMenu.showAlertMap(alert);
    }
  }, [isShowMap]);

  function handleTabs(e, val) {
    setValue(val);
  }
  function TabPanel(props) {
    const { children, value, index } = props;
    return (
      <div>
        {value === index && <React.Fragment>{children}</React.Fragment>}
      </div>
    );
  }

  const getVehicleAlertEvents = () => {
    const alertStatus = alert.info?.status;

    let returnedString = "";

    if (alertStatus) {
      if (alertStatus.coolant_overheat) {
        returnedString += ", " + Language.translate("Coolant Overheat");
      }
      if (alertStatus.engine_on) {
        returnedString += ", " + Language.translate("Engine On");
      }
      if (alertStatus.excessive_acceleration) {
        returnedString += ", " + Language.translate("Excessive Acceleration");
      }
      if (alertStatus.hard_breaking) {
        returnedString += ", " + Language.translate("Hard Braking");
      }
      if (alertStatus.oil_overheat) {
        returnedString += ", " + Language.translate("Oil Overheat");
      }
    }

    return returnedString.startsWith(",")
      ? returnedString.substring(1)
      : returnedString;
  };

  const classes = useStyles();

  return (
    <Grid item xs={12} md={12} className={classes.detailsContainer}>
      <List>
        {(alertType === "Speed" && alert.info.geofence_id) ||
        alertType === "Geofence" ? (
          <ListItem>
            <Grid item md={6} className="itemTextFirst">
              {Language.translate("Geofence")}
            </Grid>
            <Grid item md={6} className="itemTextSecond">
              {Language.translate(Geofence.get(alert.info.geofence_id).title)}
            </Grid>
          </ListItem>
        ) : null}
        <ListItem>
          <Grid item md={6} className="itemTextFirst">
            <React.Fragment>
              {Language.translate(OlMap.getUserLonlatFormat())}
              {alert.info.trigger_longitude && alert.info.trigger_latitude && (
                <CustomTooltip
                  title={Language.translate("Locate")}
                  placement="top"
                >
                  <IconButton
                    className="zoomToCoord"
                    edge="end"
                    aria-label="locate"
                    size="small"
                    onClick={() => {
                      setIsShowMap(!isShowMap);
                    }}
                  >
                    <MyLocationIcon />
                  </IconButton>
                </CustomTooltip>
              )}
            </React.Fragment>
          </Grid>
          {alert.info.trigger_longitude && alert.info.trigger_latitude ? (
            <Grid item md={6} className="itemTextSecond">
              {OlMap.formatCoordinate([
                alert.info.trigger_longitude,
                alert.info.trigger_latitude,
              ])}
            </Grid>
          ) : (
            <Grid item md={6} className="itemTextSecond">
              "N/A"
            </Grid>
          )}
        </ListItem>
        <ListItem>
          <Grid item md={6} className="itemTextFirst">
            {Language.translate("Start")}
          </Grid>
          <Grid item md={6} className="itemTextSecond">
            {Clock.formatTimestamp(alert.info.start_timestamp)}
          </Grid>
        </ListItem>
        {alert.info.end_timestamp ? (
          <ListItem>
            <Grid item md={6} className="itemTextFirst">
              {Language.translate("End")}
            </Grid>
            <Grid item md={6} className="itemTextSecond">
              {Clock.formatTimestamp(alert.info.end_timestamp)}
            </Grid>
          </ListItem>
        ) : (
          <ListItem>
            <Grid item md={6} className="itemTextFirst">
              {Language.translate("Age")}
            </Grid>
            <Grid item md={6} className="itemTextSecond">
              {age}
            </Grid>
          </ListItem>
        )}
        {alertType !== "Speed" ? (
          <ListItem>
            <Grid item md={6} className="itemTextFirst">
              <Grid container alignItems="center">
                <Grid md={2} item>
                  {Language.translate("Speed")}
                </Grid>
                <Grid md={4} item>
                  {
                    <Chip
                      size="small"
                      color="primary"
                      label={UserSetting.get().speed_format}
                    />
                  }
                </Grid>
              </Grid>
            </Grid>
            {!_.isNil(speed) ? (
              <Grid item md={6} className="itemTextSecond">
                {speed}
              </Grid>
            ) : (
              <Grid item md={6} className="itemTextSecond">
                N/A
              </Grid>
            )}
          </ListItem>
        ) : null}
        {!alert.info.geofence_id && alertType === "Speed" ? (
          <ListItem>
            <Grid item md={6} className="itemTextFirst">
              <Grid container alignItems="center">
                <Grid md={2} item>
                  {Language.translate("Speed")}
                </Grid>
                <Grid md={4} item>
                  {
                    <Chip
                      size="small"
                      color="primary"
                      label={UserSetting.get().speed_format}
                    />
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={2} className="itemTextSecond splitIn3">
              {!_.isNil(speed) ? speed : "N/A"}
            </Grid>
            <Grid
              item
              md={2}
              className={clsx(
                "itemTextSecond",
                "splitIn3",
                classes.speedGridMinMax
              )}
            >
              {
                <CustomTooltip
                  title={Language.translate("Min Speed")}
                  placement="top"
                >
                  <div>
                    {!_.isNil(Device.get(alert.device_id)?.min_speed)
                      ? Utils.transformSpeedFromKph(
                          Device.get(alert.device_id).min_speed,
                          UserSetting.get().speed_format
                        )
                      : "N/A"}
                  </div>
                </CustomTooltip>
              }
            </Grid>
            <Grid
              item
              md={2}
              className={clsx(
                "itemTextSecond",
                "splitIn3",
                classes.speedGridMinMax
              )}
            >
              {
                <CustomTooltip
                  title={Language.translate("Max Speed")}
                  placement="top"
                >
                  <span>
                    {!_.isNil(Device.get(alert.device_id)?.max_speed)
                      ? Utils.transformSpeedFromKph(
                          Device.get(alert.device_id).max_speed,
                          UserSetting.get().speed_format
                        )
                      : "N/A"}
                  </span>
                </CustomTooltip>
              }
            </Grid>
          </ListItem>
        ) : null}
        {alert.info.geofence_id && alertType === "Speed" ? (
          <ListItem>
            <Grid item md={6} className="itemTextFirst">
              <Grid container alignItems="center">
                <Grid md={2} item>
                  {Language.translate("Speed")}
                </Grid>
                <Grid md={1} item className={classes.flexCenter}>
                  {
                    <CustomTooltip title="Geofence" placement="top">
                      <div className={classes.geofenceIcon}>
                        <CustomIcon
                          type="geofence"
                          id={`icon-geofence`}
                          fontSize="small"
                        />
                      </div>
                    </CustomTooltip>
                  }
                </Grid>
                <Grid md={4} item>
                  {
                    <Chip
                      size="small"
                      color="primary"
                      label={UserSetting.get().speed_format}
                    />
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={2} className="itemTextSecond splitIn3">
              {!isNaN(speed) ? speed : "N/A"}
            </Grid>
            <Grid
              item
              md={2}
              className={clsx(
                "itemTextSecond",
                "splitIn3",
                classes.speedGridMinMax
              )}
            >
              {
                <CustomTooltip
                  title={Language.translate("Min Speed")}
                  placement="top"
                >
                  <span>
                    {Geofence.get(alert.info.geofence_id)
                      ? Utils.transformSpeedFromKph(
                          Geofence.get(alert.info.geofence_id).min_speed,
                          UserSetting.get().speed_format
                        )
                      : "N/A"}
                  </span>
                </CustomTooltip>
              }
            </Grid>
            <Grid
              md={2}
              item
              className={clsx(
                "itemTextSecond",
                "splitIn3",
                classes.speedGridMinMax
              )}
            >
              {
                <CustomTooltip
                  title={Language.translate("Max Speed")}
                  placement="top"
                >
                  <span>
                    {Geofence.get(alert.info.geofence_id)
                      ? Utils.transformSpeedFromKph(
                          Geofence.get(alert.info.geofence_id).max_speed,
                          UserSetting.get().speed_format
                        )
                      : "N/A"}
                  </span>
                </CustomTooltip>
              }
            </Grid>
          </ListItem>
        ) : null}
        <ListItem>
          <Grid item md={6} className="itemTextFirst">
            {Language.translate("Course")}
          </Grid>
          <Grid item md={6} className="itemTextSecond">
            {GuiUtils.ConvertHeadingToString(
              Device.get(alert.device_id).heading
            )}
          </Grid>
        </ListItem>
        <ListItem>
          <Grid item md={6} className="itemTextFirst">
            {Language.translate("Last Reported")}
          </Grid>
          <Grid item md={6} className="itemTextSecond">
            {Device.get(alert.device_id)
              ? Clock.formatTimestamp(
                  Device.get(alert.device_id).report_timestamp
                )
              : "N/A"}
          </Grid>
        </ListItem>
        {_.keys(alert.acknowledgements).length > 0 ? (
          <ListItem>
            <Grid item md={6} className="itemTextFirst">
              {Language.translate("Acknowledged By")}
            </Grid>
            <Grid item md={6} className="itemTextSecond">
              {!Permission.verify("user", "view")
                ? Language.translate("You do not have Permission to view Users")
                : Object.values(alert.acknowledgements).map(
                    (ack) => User.get(ack.id).username
                  )}
            </Grid>
          </ListItem>
        ) : null}
        {alertType === "Vehicle" ? (
          <ListItem>
            <Grid item md={6} className="itemTextFirst">
              {Language.translate("Event(s)")}
            </Grid>
            <Grid item md={6} className="itemTextSecond">
              {getVehicleAlertEvents()}
            </Grid>
          </ListItem>
        ) : null}
        {(alert.info.reset_user_id && alertType === "Emergency") ||
        (alert.info.reset_user_id == null &&
          alert.info.is_reset &&
          alertType === "Emergency") ? (
          <ListItem>
            <Grid item md={6} className="itemTextFirst">
              {Language.translate("Reset By")}
            </Grid>
            <Grid item md={6} className="itemTextSecond">
              {alert.info.reset_user_id
                ? User.get(alert.info.reset_user_id).username
                : alert.info.is_reset
                ? Device.get(alert.device_id).name
                : "N/A"}
            </Grid>
          </ListItem>
        ) : null}
        {alertType === "Cargo" ? (
          <ListItem className={classes.cargoDetailTabs}>
            <Tabs
              value={value}
              onChange={handleTabs}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              className={classes.cargoDetailTabsContainer}
            >
              <Tab
                label={Language.translate("Alert Info")}
                icon={<CustomIcon fontSize="small" id="icon-info-circle" />}
              />
              {Device.checkIfConsignment(Device.get(alert.device_id), false) ? (
                <Tab
                  label={Language.translate("Live Stats")}
                  icon={<CustomIcon fontSize="small" id="icon-info-circle" />}
                />
              ) : null}
            </Tabs>
            <TabPanel value={value} index={0}>
              <List>
                <ListItem
                  className={
                    Alert.getCargoAlertType(alert.info.cargo_alert_type_id)
                      .type === "Door"
                      ? classes.cargoDanger2
                      : classes.cargoDanger
                  }
                >
                  <Grid item md={6} className="itemTextFirst">
                    <span className={classes.cargoTextSpan}>
                      <MeetingRoomIcon />
                      {Language.translate("Door")}
                    </span>
                  </Grid>
                  <Grid item md={6} className="itemTextSecond">
                    {alert.info.status.door_open
                      ? Language.translate("Open")
                      : Language.translate("Closed")}
                  </Grid>
                </ListItem>
                <ListItem
                  className={
                    Alert.getCargoAlertType(alert.info.cargo_alert_type_id)
                      .type === "Temperature"
                      ? classes.cargoDanger2
                      : classes.cargoDanger
                  }
                >
                  <Grid item md={6} className="itemTextFirst">
                    <span className={classes.cargoTextSpan}>
                      <WhatshotIcon />
                      {Language.translate("Temperature")}
                    </span>
                  </Grid>
                  <Grid item md={6} className="itemTextSecond">
                    <Grid container>
                      <Grid md={4} item className={classes.textCenter}>
                        {alert.info.status.temperature !== null &&
                        alert.info.status.temperature <= 45 &&
                        alert.info.status.temperature >= -45 ? (
                          <React.Fragment>
                            {alert.info.status.temperature}&#8451;
                          </React.Fragment>
                        ) : (
                          "N/A"
                        )}
                      </Grid>
                      <Grid
                        md={4}
                        item
                        className={
                          Alert.getCargoAlertType(
                            alert.info.cargo_alert_type_id
                          ).type === "Temperature"
                            ? classes.texCenterDanger
                            : classes.textCenter
                        }
                      >
                        {
                          <CustomTooltip
                            title={Language.translate("TEMPERATURE LOW")}
                            placement="top"
                          >
                            <span>
                              {Device.get(alert.device_id).consignment.settings
                                .temp_low !== null ? (
                                <React.Fragment>
                                  {
                                    Device.get(alert.device_id).consignment
                                      .settings.temp_low
                                  }
                                  &#8451;
                                </React.Fragment>
                              ) : (
                                "N/A"
                              )}
                            </span>
                          </CustomTooltip>
                        }
                      </Grid>
                      <Grid
                        md={4}
                        item
                        className={
                          Alert.getCargoAlertType(
                            alert.info.cargo_alert_type_id
                          ).type === "Temperature"
                            ? classes.texCenterDanger
                            : classes.textCenter
                        }
                      >
                        {
                          <CustomTooltip
                            title={Language.translate("TEMPERATURE HIGH")}
                            placement="top"
                          >
                            <span>
                              {Device.get(alert.device_id).consignment.settings
                                .temp_high !== null ? (
                                <React.Fragment>
                                  {
                                    Device.get(alert.device_id).consignment
                                      .settings.temp_high
                                  }
                                  &#8451;
                                </React.Fragment>
                              ) : (
                                "N/A"
                              )}
                            </span>
                          </CustomTooltip>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem
                  className={
                    Alert.getCargoAlertType(alert.info.cargo_alert_type_id)
                      .type === "Humidity"
                      ? classes.cargoDanger2
                      : classes.cargoDanger
                  }
                >
                  <Grid item md={6} className="itemTextFirst">
                    <span className={classes.cargoTextSpan}>
                      <OpacityIcon />
                      {Language.translate("Humidity")}
                    </span>
                  </Grid>
                  <Grid item md={6} className="itemTextSecond">
                    <Grid container>
                      <Grid md={4} item className={classes.textCenter}>
                        {alert.info.status.humidity !== null &&
                        alert.info.status.humidity <= 100 &&
                        alert.info.status.humidity >= 0 ? (
                          <React.Fragment>
                            {alert.info.status.humidity}%
                          </React.Fragment>
                        ) : (
                          "N/A"
                        )}
                      </Grid>
                      <Grid
                        md={4}
                        item
                        className={
                          Alert.getCargoAlertType(
                            alert.info.cargo_alert_type_id
                          ).type === "Humidity"
                            ? classes.texCenterDanger
                            : classes.textCenter
                        }
                      >
                        {
                          <CustomTooltip
                            title={Language.translate("HUMIDITY LOW")}
                            placement="top"
                          >
                            <span>0%</span>
                          </CustomTooltip>
                        }
                      </Grid>
                      <Grid
                        md={4}
                        item
                        className={
                          Alert.getCargoAlertType(
                            alert.info.cargo_alert_type_id
                          ).type === "Humidity"
                            ? classes.texCenterDanger
                            : classes.textCenter
                        }
                      >
                        {
                          <CustomTooltip
                            title={Language.translate("HUMIDITY HIGH")}
                            placement="top"
                          >
                            <span>
                              {Device.get(alert.device_id).consignment.settings
                                .humidity_high !== null ? (
                                <React.Fragment>
                                  {
                                    Device.get(alert.device_id).consignment
                                      .settings.humidity_high
                                  }
                                  %
                                </React.Fragment>
                              ) : (
                                "N/A"
                              )}
                            </span>
                          </CustomTooltip>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </ListItem>
                {Device.getDeviceType(Device.get(alert.device_id).type_id)
                  .title !== "Shadow" ? (
                  <ListItem
                    className={
                      Alert.getCargoAlertType(alert.info.cargo_alert_type_id)
                        .type === "Shock"
                        ? classes.cargoDanger2
                        : classes.cargoDanger
                    }
                  >
                    <Grid item md={6} className="itemTextFirst">
                      <span className={classes.cargoTextSpan}>
                        <FlashOnIcon />
                        {Language.translate("Shock")}
                      </span>
                    </Grid>
                    <Grid item md={6} className="itemTextSecond">
                      <Grid container>
                        <Grid md={4} item>
                          {alert.info.status.shock ? (
                            <React.Fragment>
                              {alert.info.status.shock}G
                            </React.Fragment>
                          ) : (
                            "N/A"
                          )}
                        </Grid>
                        <Grid md={4} item>
                          {
                            <CustomTooltip
                              title={Language.translate("SHOCK LOW")}
                              placement="top"
                            >
                              <span>0G</span>
                            </CustomTooltip>
                          }
                        </Grid>
                        <Grid md={4} item>
                          {
                            <CustomTooltip
                              title={Language.translate("SHOCK HIGH")}
                              placement="top"
                            >
                              <span>
                                {Device.get(alert.device_id).consignment
                                  .settings.shock_high ? (
                                  <React.Fragment>
                                    {
                                      Device.get(alert.device_id).consignment
                                        .settings.shock_high
                                    }
                                    G
                                  </React.Fragment>
                                ) : (
                                  "N/A"
                                )}
                              </span>
                            </CustomTooltip>
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                ) : null}
                <ListItem
                  className={
                    Alert.getCargoAlertType(alert.info.cargo_alert_type_id)
                      .type === "Battery"
                      ? classes.cargoDanger2
                      : classes.cargoDanger
                  }
                >
                  <Grid item md={6} className="itemTextFirst">
                    <span className={classes.cargoTextSpan}>
                      <BatteryCharging60Icon />
                      {Language.translate("Battery")}
                    </span>
                  </Grid>
                  <Grid item md={6} className="itemTextSecond">
                    {alert.info.status.battery_charge !== null
                      ? alert.info.status.battery_charge + "%"
                      : "N/A"}
                  </Grid>
                </ListItem>
              </List>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <List>
                <ListItem
                  className={
                    Alert.getCargoAlertType(alert.info.cargo_alert_type_id)
                      .type === "Door"
                      ? classes.cargoDanger2
                      : classes.cargoDanger
                  }
                >
                  <Grid item md={6} className="itemTextFirst">
                    <span className={classes.cargoTextSpan}>
                      <MeetingRoomIcon />
                      {Language.translate("Door")}
                    </span>
                  </Grid>
                  <Grid item md={6} className="itemTextSecond">
                    {Device.get(alert.device_id).consignment.status.door_open
                      ? Language.translate("Open")
                      : Language.translate("Closed")}
                  </Grid>
                </ListItem>
                <ListItem
                  className={
                    Alert.getCargoAlertType(alert.info.cargo_alert_type_id)
                      .type === "Temperature"
                      ? classes.cargoDanger2
                      : classes.cargoDanger
                  }
                >
                  <Grid item md={6} className="itemTextFirst">
                    <span className={classes.cargoTextSpan}>
                      <WhatshotIcon />
                      {Language.translate("Temperature")}
                    </span>
                  </Grid>
                  <Grid item md={6} className="itemTextSecond">
                    <Grid container>
                      <Grid md={4} item className={classes.textCenter}>
                        {Device.get(alert.device_id).consignment.status
                          .temperature &&
                        Device.get(alert.device_id).consignment.status
                          .temperature <= 55 &&
                        Device.get(alert.device_id).consignment.status
                          .temperature >= -40 ? (
                          <React.Fragment>
                            {
                              Device.get(alert.device_id).consignment.status
                                .temperature
                            }
                            &#8451;
                          </React.Fragment>
                        ) : (
                          "N/A"
                        )}
                      </Grid>
                      <Grid
                        md={4}
                        item
                        className={
                          Alert.getCargoAlertType(
                            alert.info.cargo_alert_type_id
                          ).type === "Temperature"
                            ? classes.texCenterDanger
                            : classes.textCenter
                        }
                      >
                        {
                          <CustomTooltip
                            title={Language.translate("TEMPERATURE LOW")}
                            placement="top"
                          >
                            <span>
                              {Device.get(alert.device_id).consignment.settings
                                .temp_low ? (
                                <React.Fragment>
                                  {
                                    Device.get(alert.device_id).consignment
                                      .settings.temp_low
                                  }
                                  &#8451;
                                </React.Fragment>
                              ) : (
                                "N/A"
                              )}
                            </span>
                          </CustomTooltip>
                        }
                      </Grid>
                      <Grid
                        md={4}
                        item
                        className={
                          Alert.getCargoAlertType(
                            alert.info.cargo_alert_type_id
                          ).type === "Temperature"
                            ? classes.texCenterDanger
                            : classes.textCenter
                        }
                      >
                        {
                          <CustomTooltip
                            title={Language.translate("TEMPERATURE HIGH")}
                            placement="top"
                          >
                            <span>
                              {Device.get(alert.device_id).consignment.settings
                                .temp_high ? (
                                <React.Fragment>
                                  {
                                    Device.get(alert.device_id).consignment
                                      .settings.temp_high
                                  }
                                  &#8451;
                                </React.Fragment>
                              ) : (
                                "N/A"
                              )}
                            </span>
                          </CustomTooltip>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem
                  className={
                    Alert.getCargoAlertType(alert.info.cargo_alert_type_id)
                      .type === "Humidity"
                      ? classes.cargoDanger2
                      : classes.cargoDanger
                  }
                >
                  <Grid item md={6} className="itemTextFirst">
                    <span className={classes.cargoTextSpan}>
                      <OpacityIcon />
                      {Language.translate("Humidity")}
                    </span>
                  </Grid>
                  <Grid item md={6} className="itemTextSecond">
                    <Grid container>
                      <Grid md={4} item className={classes.textCenter}>
                        {Device.get(alert.device_id).consignment.status
                          .humidity &&
                        Device.get(alert.device_id).consignment.status
                          .humidity <= 100 &&
                        Device.get(alert.device_id).consignment.status
                          .humidity >= 0 ? (
                          <React.Fragment>
                            {
                              Device.get(alert.device_id).consignment.status
                                .humidity
                            }
                            %
                          </React.Fragment>
                        ) : (
                          "N/A"
                        )}
                      </Grid>
                      <Grid
                        md={4}
                        item
                        className={
                          Alert.getCargoAlertType(
                            alert.info.cargo_alert_type_id
                          ).type === "Humidity"
                            ? classes.texCenterDanger
                            : classes.textCenter
                        }
                      >
                        {
                          <CustomTooltip
                            title={Language.translate("HUMIDITY LOW")}
                            placement="top"
                          >
                            <span>0%</span>
                          </CustomTooltip>
                        }
                      </Grid>
                      <Grid
                        md={4}
                        item
                        className={
                          Alert.getCargoAlertType(
                            alert.info.cargo_alert_type_id
                          ).type === "Humidity"
                            ? classes.texCenterDanger
                            : classes.textCenter
                        }
                      >
                        {
                          <CustomTooltip
                            title={Language.translate("HUMIDITY HIGH")}
                            placement="top"
                          >
                            <span>
                              {Device.get(alert.device_id).consignment.settings
                                .humidity_high ? (
                                <React.Fragment>
                                  {
                                    Device.get(alert.device_id).consignment
                                      .settings.humidity_high
                                  }
                                  %
                                </React.Fragment>
                              ) : (
                                "N/A"
                              )}
                            </span>
                          </CustomTooltip>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </ListItem>
                {Device.getDeviceType(Device.get(alert.device_id).type_id)
                  .title !== "Shadow" ? (
                  <ListItem
                    className={
                      Alert.getCargoAlertType(alert.info.cargo_alert_type_id)
                        .type === "Shock"
                        ? classes.cargoDanger2
                        : classes.cargoDanger
                    }
                  >
                    <Grid item md={6} className="itemTextFirst">
                      <span className={classes.cargoTextSpan}>
                        <FlashOnIcon />
                        {Language.translate("Shock")}
                      </span>
                    </Grid>
                    <Grid item md={6} className="itemTextSecond">
                      <Grid container>
                        <Grid md={4} item>
                          {alert.info.status.shock ? (
                            <React.Fragment>
                              {
                                Device.get(alert.device_id).consignment.status
                                  .shock
                              }
                              G
                            </React.Fragment>
                          ) : (
                            "N/A"
                          )}
                        </Grid>
                        <Grid md={4} item>
                          {
                            <CustomTooltip
                              title={Language.translate("SHOCK LOW")}
                              placement="top"
                            >
                              <span>0G</span>
                            </CustomTooltip>
                          }
                        </Grid>
                        <Grid md={4} item>
                          {
                            <CustomTooltip
                              title={Language.translate("SHOCK HIGH")}
                              placement="top"
                            >
                              <span>
                                {Device.get(alert.device_id).consignment
                                  .settings.shock_high ? (
                                  <React.Fragment>
                                    {
                                      Device.get(alert.device_id).consignment
                                        .settings.shock_high
                                    }
                                    G
                                  </React.Fragment>
                                ) : (
                                  "N/A"
                                )}
                              </span>
                            </CustomTooltip>
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                ) : null}
                <ListItem
                  className={
                    Alert.getCargoAlertType(alert.info.cargo_alert_type_id)
                      .type === "Battery"
                      ? classes.cargoDanger2
                      : classes.cargoDanger
                  }
                >
                  <Grid item md={6} className="itemTextFirst">
                    <span className={classes.cargoTextSpan}>
                      <BatteryCharging60Icon />
                      {Language.translate("Battery")}
                    </span>
                  </Grid>
                  <Grid item md={6} className="itemTextSecond">
                    {Device.get(alert.device_id).consignment.status
                      .battery_charge !== null
                      ? Device.get(alert.device_id).consignment.status
                          .battery_charge + "%"
                      : "N/A"}
                  </Grid>
                </ListItem>
              </List>
            </TabPanel>
          </ListItem>
        ) : null}
        {isShowMap ? (
          <React.Fragment>
            <div id="alertMapView" className={classes.alertMap}>
              <div style={{ display: "none" }} id="alertMapLayerSwitcher"></div>
            </div>
            <div id="alertMapAttrDisplay" />
          </React.Fragment>
        ) : null}
        {alert.info.reset_user_id &&
        !alert.info.is_reset &&
        AlertMenu.showHideBtn(alert.device_id) ? (
          <CustomButton
            onClick={() => {
              Alert.hardReset(
                alert.id
              ); /*hardResetting[alert.id] = !hardResetting[alert.id]*/
            }}
            size="medium"
            color="command"
            variant="contained"
          >
            {Language.translate("Hide")}
          </CustomButton>
        ) : null}
        {!alert.info.is_reset &&
        alertType === "Emergency" &&
        Permission.verify("alarm", "reset") &&
        alert.info.vehicle_alert_type_id === null &&
        !deviceTypesCannotReset.includes(device.type) ? (
          <ListItem className={classes.resetListItem}>
            {alert.info.reset_user_id && !alert.info.is_reset ? (
              <CustomButton
                disabled={true}
                onClick={() => null}
                size="medium"
                color="command"
                variant="contained"
                startIcon={
                  <CircularProgress className={classes.pendingCircle} />
                }
              >
                {Language.translate("Pending")}
              </CustomButton>
            ) : (
              <CustomButton
                disabled={alert.info.reset_user_id && !alert.info.is_reset}
                onClick={() => Alert.reset(alert.id)}
                size="medium"
                color="command"
                variant="contained"
                startIcon={<RotateLeftIcon />}
              >
                {Language.translate("Reset")}
              </CustomButton>
            )}
          </ListItem>
        ) : null}
      </List>
    </Grid>
  );
}
