import React, { useContext } from "react";

// Material-UI
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

// Components
import CustomTooltip from "@Components/CustomTooltip";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import CustomIcon from "@Components/CustomIcon";

// Styles
import { moduleListing } from "@Styles/ModuleListing";

// Internal
import POIDetail from "./POIDetail";
import olMap from "sccOlMapNew";
import Device from "sccDevice";
import Poi from "sccPoi";
import PoiMenu from "sccPoiMenu";
import Images from "sccImage";
import Language from "sccLanguage";
import { PoiCategoryContext } from "../context/PoiCategoryContext";

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),
  refreshIcon: {
    position: "absolute",
    top: 10,
    paddingLeft: 6,
  },
  zoomToCoord: {
    cursor: "pointer",
    display: "inline-flex",
    alignItems: "center",
    "& svg": {
      width: 12,
      height: 12,
      paddingLeft: 4,
    },
  },
}));

export default function POIList(props) {
  const data = props.data;
  const [mwState] = useContext(ModuleWrapperContext);
  const { selectedPoiCategoryArr } = useContext(PoiCategoryContext);
  const handleClick = props.handleClick;
  const openDetailsFor = props.openDetailsFor;
  const handleEdit = mwState.handleItemEdit;
  const handleItemDelete = mwState.handleItemDelete;

  function zoomToCoord(longitude, latitude) {
    const zoom = olMap.mapQuickZoomInLevel;
    const orgCoord = [longitude, latitude];
    const coord = olMap.transformToMapCoordinate(orgCoord);
    olMap.setCenterAndZoom(coord, zoom);
  }
  const handleAccept = (poi) => {
    PoiMenu.poiSyncAccept(poi);
  };

  const handleDecline = (poi) => {
    PoiMenu.poiSyncReject(poi);
  };

  // only show location button if selectedPoiCategory is empty (default) or category is selected
  const isShowLocationButton = (data) => {
    return (
      selectedPoiCategoryArr.length === 0 ||
      selectedPoiCategoryArr.includes(Number(data.category_id))
    );
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <ListItem
        className={`${classes.listItem} ${data.active && classes.enabled}`}
        key={data.id}
        onClick={() => handleClick(data.id)}
      >
        <ListItemIcon className={classes.itemIcon}>
          <CustomTooltip title={data.title} placement="left">
            <span>
              {data.image_id == null ? (
                <img
                  className="poi-marker-img"
                  name="image"
                  alt={data.title}
                  src={Poi.getNatoSymbol(data.nato_code)}
                  required
                />
              ) : (
                <img
                  className="poi-marker-img-platform"
                  name="image"
                  alt={data.title}
                  src={Images.getImageSource(data.image_id)}
                />
              )}
            </span>
          </CustomTooltip>
        </ListItemIcon>
        <ListItemText
          id={data.id}
          className={classes.itemPrimaryText}
          primary={
            <React.Fragment>
              {data.title}
              {data.nato_code && Device.getAvaiableDevices(data.sync?.devices)?.length > 0 && (
                <ListItemIcon>
                  <span className={classes.refreshIcon}>
                    <CustomIcon id="icon-refresh" style={{ fontSize: 9 }} />
                  </span>
                </ListItemIcon>
              )}
            </React.Fragment>
          }
        />
        <ListItemSecondaryAction className={classes.itemActionButtonsContainer}>
          {!data.approved && (
            <React.Fragment>
              <CustomTooltip
                title={Language.translate("Accept")}
                placement="bottom"
              >
                <IconButton
                  edge="end"
                  aria-label="accept"
                  onClick={() => handleAccept(data)}
                >
                  <CheckCircleIcon />
                </IconButton>
              </CustomTooltip>
              <CustomTooltip
                title={Language.translate("Decline")}
                placement="bottom"
              >
                <IconButton
                  edge="end"
                  aria-label="decline"
                  onClick={() => handleDecline(data)}
                >
                  <CancelIcon />
                </IconButton>
              </CustomTooltip>
            </React.Fragment>
          )}
          {data.approved && isShowLocationButton(data) ? (
            <CustomTooltip title={Language.translate("Locate")} placement="top">
              <IconButton
                className="zoomToCoord"
                edge="end"
                aria-label="locate"
                onClick={() => zoomToCoord(data.longitude, data.latitude)}
              >
                <MyLocationIcon />
              </IconButton>
            </CustomTooltip>
          ) : null}
          {mwState.verifyEditPermission && data.approved ? (
            <CustomTooltip title={Language.translate("Edit")} placement="top">
              <IconButton
                edge="end"
                aria-label="edit"
                onClick={() => handleEdit(data)}
              >
                <EditIcon />
              </IconButton>
            </CustomTooltip>
          ) : null}
          {mwState.verifyDeletePermission && data.approved ? (
            <CustomTooltip title={Language.translate("Delete")} placement="top">
              <IconButton
                edge="end"
                aria-label="comments"
                onClick={() =>
                  handleItemDelete(
                    `${mwState.routeUrl}/${data.id}`,
                    "Poi",
                    "POI",
                    data.title
                  )
                }
              >
                <DeleteIcon />
              </IconButton>
            </CustomTooltip>
          ) : null}
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={openDetailsFor === data.id} timeout="auto" unmountOnExit>
        <POIDetail data={data} />
      </Collapse>
    </React.Fragment>
  );
}
