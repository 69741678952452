import React, { useEffect, useState, useContext } from "react";
import Load from "sccLoad";
import { default as Permission } from "sccPermission";
import { default as EnvVar } from "sccEnvVar";
import { default as Images } from "sccImage";
import * as Language from "sccLanguage";
import { default as OlMap } from "sccOlMapNew";
import { default as Clock } from "sccClock";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomButton from "@Components/CustomButton";
import { default as Poi } from "sccPoi";
import { default as Device } from "sccDevice";
import { default as Options } from "sccOptions";
import { default as Profile } from "sccProfile";
import { default as Geofence } from "sccGeofence";
import Grid from "@material-ui/core/Grid";
import { default as UserSetting } from "sccUserSetting";
import { default as Chat } from "sccChat";
import { default as Message } from "sccMessage";
import { default as AdminDevice } from "sccAdminDevice";
import Modal from "@material-ui/core/Modal";
import { default as Alert } from "sccAlert";
import { default as Maps } from "sccMaps";
import { default as Group } from "sccGroup";
import { default as Cargo } from "sccCargo";
import { default as DeviceOverlay } from "sccDeviceOverlay";
import { default as Socket } from "sccSocket";
import { default as GeofenceOverlay } from "sccGeofenceOverlay";
import { default as PoiOverlay } from "sccPoiOverlay";
import { default as PoiCategory } from "sccPoiCategory";
import { default as PoiMenu } from "sccPoiMenu";
import { default as AlertMenu } from "sccAlertMenu";
import { default as DeviceMenu } from "sccDeviceMenu";
import { default as PermissionMenu } from "sccPermissionMenu";
import { makeStyles } from "@material-ui/core/styles";
import { AppContext } from "../../../AppContext";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import Map from "../../map/components/Map/index.js";
import MapTopLeftLogoZones from "../../clock/components/MapTopLeftLogoZones";
import GoogleMap from "../../map/components/GoogleMap";
import MapBottomLeft from "../../map/components/MapBottomLeftScale";
//to be used later
import CoordSearchbar from "../../map/components/CoordSearchbar";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "fixed",
    zIndex: 50000,
    top: 20,
    left: "0",
    width: "102%",
    height: "100%",
    backgroundColor: theme.palette.colors.blue.dark,
    padding: theme.spacing(1),
    color: theme.palette.colors.white.main,
  },
  logo: {
    width: "200px",
  },
  soundsConfirmOverLay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: 999,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    transition: "all 0.5s ease",
  },
  soundsConfirmBox: {
    position: "absolute",
    width: "auto",
    height: "auto",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
    backgroundColor: "#fff",
    padding: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    transition: "all 0.5s ease",
  },
  displayNone: {
    display: "none",
  },
  soundsConfirmText: {
    fontSize: 18,
    fontFamily: "'proxima-nova', sans-serif",
  },
  buttonGroup: {
    marginTop: 8,
    display: "flex",
    justifyContent: "space-around",
  },
  soundsConfirmButton: {
    marginTop: 8,
    color: "#fff",
    backgroundColor: "#2e6da4",
    width: 100,
  },
}));

//Module load based on the sequence followed in the angular based system
// permission, env_var, language, image, poi/category, user_setting, profile, profile_menu
// data_display, message, map, clock, alert, alert_menu, device, device_menu, group, cargo
// device_overlay, socket, poi, poi_overlay, poi_menu, geofence, geofence_overlay, geofence_menu
// nr, nr_menu, sa, sa_menu, client, ar, ar_menu, message, message_menu, user_setting_menu
// user, user_menu, permission_menu, options

function ExtendedMaps() {
  const classes = useStyles();
  window.Promise = require("bluebird");

  const [resolvedModuleCount, setResolvedModuleCount] = useState(0);
  const [totalModuleCount, setTotalModuleCount] = useState(0);
  const [initialized, setInitialized] = useState(Load.initialized);
  const [allModulesLoaded, setAllModulesLoaded] = useState(
    Load.allModulesLoaded
  );
  const [open, setOpen] = React.useState(true);
  const [refreshText] = React.useState(Language.default.translate("Refresh"));
  const [appState] = useContext(AppContext);
  const [mwState] = useContext(ModuleWrapperContext);

  let $scope = mwState.$scope;
  var sequencedInit = [
    {
      module: Permission,
      dependencies: [],
    },
    {
      module: EnvVar,
      dependencies: [],
    },
    {
      module: Language.default,
      dependencies: [],
    },
    {
      module: Images,
      dependencies: [],
    },
    {
      module: UserSetting,
      dependencies: [],
    },
    {
      module: Options,
      dependencies: [],
    },
    {
      module: Profile,
      dependencies: [],
    },
    {
      module: Clock,
      dependencies: ["user_setting"],
    },
    {
      module: { moduleName: "permission_menu" },
      dependencies: ["permission"],
      initFunc: function () {
        return PermissionMenu.init($scope);
      },
    },
    {
      module: Device,
      dependencies: ["permission"],
    },
    {
      module: { moduleName: "device_menu" },
      dependencies: ["device"],
      initFunc: function () {
        return DeviceMenu.init($scope);
      },
    },
    {
      module: Poi,
      dependencies: ["permission"],
    },
    {
      module: { moduleName: "poi_menu" },
      dependencies: ["poi"],
      initFunc: function () {
        return PoiMenu.init($scope);
      },
    },
    {
      module: Message,
      dependencies: ["permission"],
    },
    {
      module: Chat,
      dependencies: ["permission"],
    },
    {
      module: AdminDevice,
      dependencies: ["permission"],
    },
    {
      module: Alert,
      dependencies: ["permission"],
    },
    {
      module: { moduleName: "alert_menu" },
      dependencies: ["alert"],
      initFunc: function () {
        return AlertMenu.init($scope);
      },
    },
    {
      module: Maps,
      dependencies: ["permission"],
    },
    {
      module: Geofence,
      dependencies: ["permission", "device"],
    },
    {
      module: OlMap,
      dependencies: ["user_setting", "env_var"],
    },
    {
      module: Group,
      dependencies: ["device", "env_var"],
    },
    {
      module: Cargo,
      dependencies: ["permission", "env_var"],
    },
    {
      module: DeviceOverlay,
      dependencies: ["map", "device", "user_setting", "alert"],
    },
    {
      module: Socket,
      dependencies: ["device"],
      //geofence
    },
    {
      module: GeofenceOverlay,
      dependencies: ["map", "geofence"],
      //poi
    },
    {
      module: PoiCategory,
      dependencies: ["permission"],
    },
    {
      module: PoiOverlay,
      dependencies: ["image", "map"],
    },
  ];

  const loader = (dependencies, module, initFunc) => {
    return new Promise((resolve) => {
      Load.inject(dependencies, module, initFunc);
      resolve(module);
    });
  };

  const startTime = Date.now();
  const doNextPromise = (index) => {
    loader(
      sequencedInit[index].dependencies,
      sequencedInit[index].module,
      sequencedInit[index].initFunc
    ).then((module) => {
      index++;
      if (index < sequencedInit.length) {
        doNextPromise(index);
      } else
        console.log(`@@@Total: ${(Date.now() - startTime) / 1000} seconds.`);
    });
  };

  const updateLoaderStats = () => {
    const updateInterval = setInterval(() => {
      setTotalModuleCount((p) => Load.totalModuleCount);
      setResolvedModuleCount((p) => Load.resolvedModuleCount);
      if (
        Load.totalModuleCount === Load.resolvedModuleCount &&
        Load.totalModuleCount > 10
      ) {
        setAllModulesLoaded(true);
        if (
          window.googleMapsAPIloaded ||
          window.googleMapsAPIresponseWaitExpired
        ) {
          setInitialized(true);
          clearInterval(updateInterval);
        }
      }
    }, 100);
  };

  useEffect(() => {
    doNextPromise(0);
    Load.resolve();
    updateLoaderStats();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  function displaySnackbarMessage(e) {
    const title = e.target.getAttribute("data-title");
    const message = e.target.getAttribute("data-message");
    const subMessage = e.target.getAttribute("data-submessage");
    const variant = e.target.getAttribute("data-variant");
    const acknowledgeAlertId = e.target.getAttribute("data-acknowledgealertid");
    appState.displaySnackbarMessage({
      title: title,
      message: message,
      subMessage: subMessage !== "null" ? subMessage : null,
      variant: variant,
      acknowledgeAlertId: acknowledgeAlertId,
    });
  }

  return (
    <>
      {!initialized ? (
        <Modal open={open} onClose={handleClose}>
          <Grid
            container
            spacing={5}
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            className={classes.paper}
          >
            <Grid item>
              <CircularProgress color="inherit" size="15rem" thickness={5} />
            </Grid>
            <Grid item>
              <img
                className={classes.logo}
                src={
                  Images.getImageCollection("platform_images").scc_titan_logo
                    .default
                }
                alt="TITAN BY NORTAC"
              />
            </Grid>
            {!allModulesLoaded ? (
              <Grid item>
                <p>
                  {Language.default.translate("Loading")}&nbsp;
                  {resolvedModuleCount}&nbsp;
                  {Language.default.translate("of")}&nbsp;
                  {totalModuleCount}&nbsp;
                  {Language.default.translate("Modules")}
                </p>
              </Grid>
            ) : null}
            {allModulesLoaded && !initialized ? (
              <Grid item>
                <p>
                  {Language.default.translate("Initializing")}&nbsp;
                  {Language.default.translate("Maps")}
                </p>
              </Grid>
            ) : null}
            <Grid container item justifyContent="flex-end">
              <CustomButton
                size="large"
                color="cancel"
                id="btnRefresh"
                variant="contained"
                type="submit"
              >
                {refreshText}
              </CustomButton>
            </Grid>
          </Grid>
        </Modal>
      ) : null}
      {initialized && !Permission.verify("client", "view") && <MapBottomLeft />}
      <CoordSearchbar />
      <MapTopLeftLogoZones />
      <Map />
      <GoogleMap />
      <button
        style={{ display: "none" }}
        type="button"
        onClick={displaySnackbarMessage}
        data-title=""
        data-message=""
        data-submessage=""
        data-variant=""
        data-acknowledgealertid=""
        id="snackbarHelper"
      >
        Hidden Button
      </button>
    </>
  );
}

export default ExtendedMaps;
