import React, { useState, useContext } from "react";

// External
import _ from "lodash";
import * as Yup from "yup";

// Material-UI
import Grid from "@material-ui/core/Grid";
import CustomTooltip from "@Components/CustomTooltip";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import EditIcon from "@material-ui/icons/Edit";
import ErrorIcon from "@material-ui/icons/Error";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import Chip from "@material-ui/core/Chip";

// Internal
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import { AppContext } from "../../../AppContext";
import CustomIcon from "@Components/CustomIcon";
import CustomTextField from "@Components/CustomTextField";
import CustomButton from "@Components/CustomButton";
import baseSchema from "../../../validation/BaseValidation";
import Language from "sccLanguage";
import Permission from "sccPermission";
import Images from "sccImage";
import Poi from "sccPoi";
import PoiCategory from "sccPoiCategory";

function POICategorySettings({
  pageState,
  setPageState,
  disableButton,
  setDisableButton,
  uploadFileDisplay,
  setUploadFileDisplay,
  filesToUpload,
  setFilesToUpload,
  filesToDelete,
  setFilesToDelete,
}) {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const [appState] = useContext(AppContext);

  const routeUrlPoiDeleteImages = "/api/v1/poi/category/image";
  const routeUrlCategory = "api/v1/poi/category";

  const schema = Yup.object().shape({
    title: baseSchema(Language).requiredStringMinMax,
  });

  const updateModuleData = mwState.updateModuleData;

  //to be used in the listing page
  const [categoryData, setCategoryData] = useState(
    Object.values(PoiCategory.get())
  );
  const [error, setError] = useState({}); // contains the data only for 1 single category
  const categoryItemData = pageState.categoryItemData;
  const [imagesCollection, setImagesCollection] = useState([]);

  function cancelAndBackToListingMode() {
    setFilesToDelete([]);
    setPageState((p) => ({
      ...p,
      pageDisplayMode: 1,
      pageTitle: Language.translate("Category List"),
    }));
  }
  function saveAndBackToList(e) {
    e.preventDefault();

    const categoryItemDataToPost = {
      ...categoryItemData,
      images: filesToUpload,
    };
    schema
      .validate(categoryItemDataToPost)
      .then(() => {
        setError({});
        const message = {
          title: "Category",
          text:
            "Successfully " +
            (categoryItemDataToPost.id ? "Updated" : "Created") +
            " " +
            categoryItemDataToPost.title,
          subText: null,
        };
        updateModuleData(
          categoryItemDataToPost,
          routeUrlCategory,
          message
        ).then((data) => {
          //re-populates module data for the General, Map, and POI tab
          setMwState((p) => ({
            ...p,
            moduleItemData: { ...p.moduleData },
            moduleItemDataOriginal: { ...p.moduleData },
          }));

          // update category list, to make sure data get updated, refill poiCategoryData with returned updated result
          if (data?.result) {
            const poiCategoryData = PoiCategory.get();

            poiCategoryData[data.result.id] = data.result;

            setCategoryData(Object.values(poiCategoryData));
          }
        });
        cancelAndBackToListingMode(); //once saved go back to the listing mode
        resetForm();
      })
      .catch((e) => {
        setError({ message: e.message });
        return false;
      });
    return false;
  }

  function removeAll() {
    setFilesToUpload([]);
    setUploadFileDisplay([]);
    setImageNameTaken([]);
  }

  function removeErrors() {
    setUploadFileDisplay(
      uploadFileDisplay.filter(function (obj) {
        return imageNameTaken.indexOf(obj.name) === -1;
      })
    );
    setImageNameTaken([]);
  }

  function removeError(file) {
    setFilesToUpload(filesToUpload.filter((f) => f.name !== file.name));
    setUploadFileDisplay(
      uploadFileDisplay.filter(function (obj) {
        return obj !== file;
      })
    );
  }
  const setImagesState = () => {
    setImagesCollection(PoiCategory.get(categoryItemData.id).images);
  };

  const [imageNameTaken, setImageNameTaken] = useState([]);

  function okAction(routeUrl, title, moduleName, itemName) {
    cancelAndBackToListingMode();
    resetForm();
    mwState.setDialogOpen(false);
  }
  const handleDialogOpen = () => {
    mwState.setDialogOkAction(() => okAction());
    mwState.setDialogCancelAction(() => cancelAction());
    mwState.setDialogOpen(true);
  };
  function okActionDelete(routeUrl, title, moduleName, itemName) {
    mwState.setDialogMessage(null);
    mwState.setDialogOpen(false);
    mwState
      .deleteModuleItemData(routeUrl, title, moduleName, itemName)
      .then((data) => {
        // update category list, to make sure data get updated, filter poiCategoryData with returned updated result id
        if (data?.result) {
          const poiCategoryData = categoryData.filter(
            (category) => category.id !== data.result.id
          );
          setCategoryData(poiCategoryData);
        }
      });
    setPageState((p) => ({
      ...p,
      pageDisplayMode: 1,
    }));
  }
  function cancelAction() {
    mwState.setDialogMessage(null);
    mwState.setDialogOpen(false);
    // resetForm();
  }
  function handleEdit(data) {
    setPageState((p) => ({
      ...p,
      pageDisplayMode: 2,
      categoryItemData: data,
      categoryItemDataOriginal: data,
      pageTitle: Language.translate("Edit Category"),
    }));
    setDisableButton(true);
    setImagesCollection(PoiCategory.get(data.id).images);
  }
  function handleCategoryDelete(data) {
    const dialogText = `${Language.translate(
      "Are you sure you want to delete the category"
    )} ${data.title}?`;
    mwState.setDialogMessage(dialogText);
    mwState.setDialogOkAction(() =>
      okActionDelete(
        routeUrlCategory + "/" + data.id,
        "Category",
        "Category",
        data.title
      )
    );
    mwState.setDialogCancelAction(() => cancelAction(false));
    mwState.setDialogOpen(true);
  }

  function handleCategoryName(e) {
    e.preventDefault();
    setPageState((p) => ({
      ...p,
      categoryItemData: {
        ...p.categoryItemData,
        title: e.target.value,
      },
    }));
    schema
      .validate({ title: e.target.value })
      .then(() => {
        setError({});
        setDisableButton(false);
      })
      .catch((e) => {
        setError({ message: e.message });
        setDisableButton(true);
        return false;
      });
  }

  function changeToAddMode(e) {
    e.preventDefault();
    setPageState((p) => ({
      ...p,
      pageDisplayMode: 2,
      categoryItemData: {},
      categoryItemDataOriginal: {},
      pageTitle: Language.translate("Add Category"),
    }));
    setDisableButton(true);
  }

  const handleFileRead = (event) => {
    const maxFileSize = 1000000; // 1MB in byte
    Array.from(event.target.files).forEach(async (file) => {
      const base64 = await convertBase64(file);
      const data = base64.substring(base64.indexOf(",") + 1);
      const img = {};
      img.name = file.name;
      img.type = file.type;
      img.size = file.size;
      img.data = data;

      const filter = _.filter(Images.get(), { name: file.name });

      if (!isFileImage(file)) {
        appState.displaySnackbarMessage({
          title: Language.translate("Incorrect Image Type"),
          message: Language.translate("Please upload image type files only"),
          subMessage: file.name,
          variant: "error",
        });
        return;
        // check if image size is bigger than 1MB
      } else if (file.size > maxFileSize) {
        appState.displaySnackbarMessage({
          title: Language.translate("Image is too large"),
          message: Language.translate("The maximum image size is 1MB"),
          subMessage: file.name,
          variant: "error",
        });
        return;
        //checking previously added files in db
      } else if (filter.length > 0) {
        //files with same name exist in db
        const checkName = _.includes(imageNameTaken, file.name);
        if (!checkName) {
          setImageNameTaken((p) => [...p, file.name]);
          setUploadFileDisplay((p) => [...p, file]);
        }
      } else {
        //checking if the file was already selected for upload
        const filterList = _.filter(filesToUpload, { name: file.name });
        if (filterList.length > 0) {
          appState.displaySnackbarMessage({
            title: Language.translate("Duplicate Image"),
            message:
              Language.translate("You have already uploaded") + " " + file.name,
            subMessage: null,
            variant: "error",
          });
          return;
        } else {
          // setImageNameTaken(p=>[...p,file.name]);
          setUploadFileDisplay((p) => [...p, file]);
          setFilesToUpload((p) => [...p, img]);
        }
      }
    });
    setPageState((p) => ({
      ...p,
      categoryItemData: { ...p.categoryItemData, images: filesToUpload },
    }));
    setDisableButton(false);
    document.getElementById("contained-button-file").value = "";
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  function isFileImage(file) {
    return file && file["type"].split("/")[0] === "image";
  }

  function checkCategoryImages(category) {
    const images = PoiCategory.get(category.id).images;
    const array = createPoiImageArray();
    const difference = _.intersection(images, array);
    return difference.length > 0;
  }
  function createPoiImageArray() {
    const poiImageArray = [];
    _.each(Poi.get(), function (value) {
      if (value.image_id) {
        poiImageArray.push(value.image_id);
      }
    });
    return poiImageArray;
  }
  function isPoiImageUsed(imgId) {
    const array = createPoiImageArray();
    return array.indexOf(imgId) > -1;
  }

  function deleteCategoryImages(id) {
    const options = {
      url: `${routeUrlPoiDeleteImages}/${id}/[${filesToDelete}]`,
      method: "DELETE",
    };
    setFilesToDelete([]);
    const hiddenBtn = document.getElementById("hiddenBtn");
    if (hiddenBtn) {
      hiddenBtn.click();
    }
    return fetch(options.url, {
      method: options.method,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        //we need to check for the 400 error here
        if (data.error) {
          appState.displaySnackbarMessage({
            title: "Category",
            message: data.error,
            variant: "error",
          });
          console.log("Error deleting categories", data.error);
        } else {
          resetForm();
          setDisableButton(false);
          console.log("Data DELETION Successful:", data);
        }
      })
      .catch((e) => {
        appState.displaySnackbarMessage({
          title: "Category",
          message: "Error encountered while deleting item",
          variant: "error",
        });
        console.log("ERROR from catch", e);
      });
  }

  function mark(Id) {
    if (document.getElementById(Id).className === "imgDisabled") return;

    //creates border
    setFilesToDelete(
      _.xor(filesToDelete, [document.getElementById(Id.toLowerCase()).value])
    );
    document.getElementById(Id).className =
      document.getElementById(Id).className === "imgChecked"
        ? "imgUnChecked"
        : "imgChecked";
  }

  function resetForm() {
    setFilesToUpload([]);
    setUploadFileDisplay([]);
    setImageNameTaken([]);
  }

  return (
    <div className="settingTabBody">
      <Grid container>
        <Grid item xs={9}>
          <h4
            style={{
              fontWeight: "normal",
              fontSize: "24px",
              marginTop: "0px",
              marginBottom: "10px",
            }}
          >
            {pageState.pageTitle}
          </h4>
        </Grid>
        {pageState.pageDisplayMode === 1 && (
          <Grid item align="right" xs={2}>
            <CustomButton
              onClick={changeToAddMode}
              size="medium"
              color="command"
              id="btnAdd"
              variant="contained"
              startIcon={<KeyboardArrowRightIcon />}
            >
              {Language.translate("Add")}
            </CustomButton>
          </Grid>
        )}
        {pageState.pageDisplayMode === 2 && (
          <Grid item xs={3}>
            <CustomButton
              onClick={
                disableButton ? cancelAndBackToListingMode : handleDialogOpen
              }
              size="medium"
              color="cancel"
              id="btnCancel"
              variant="contained"
              startIcon={<ArrowBackIosIcon />}
            >
              {Language.translate("Back To List").toUpperCase()}
            </CustomButton>
          </Grid>
        )}
      </Grid>
      {pageState.pageDisplayMode === 1 ? (
        <ul className="listing">
          {!_.isEmpty(categoryData) ? (
            categoryData.map((data) => {
              return (
                <ListItem key={data.id} className="listItem">
                  <ListItemText
                    id={data.id}
                    primary={`${data.title} (${data.images.length})`}
                  />
                  <ListItemSecondaryAction>
                    <React.Fragment>
                      {(Permission.verify("client", "view") ||
                        (Permission.verify("poi", "edit") &&
                          data.client_id != null)) && (
                        <CustomTooltip
                          title={Language.translate("Edit")}
                          placement="left"
                        >
                          <IconButton
                            edge="end"
                            aria-label="edit"
                            onClick={() => handleEdit(data)}
                          >
                            <EditIcon />
                          </IconButton>
                        </CustomTooltip>
                      )}
                      {(Permission.verify("client", "view") ||
                        (Permission.verify("poi", "edit") &&
                          data.client_id != null &&
                          !checkCategoryImages(data))) && (
                        <CustomTooltip
                          title={Language.translate("Delete")}
                          placement="bottom"
                        >
                          <IconButton
                            edge="end"
                            aria-label="comments"
                            onClick={() => handleCategoryDelete(data)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </CustomTooltip>
                      )}
                    </React.Fragment>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })
          ) : (
            <div align="center">{Language.translate("No results")}</div>
          )}
        </ul>
      ) : (
        <div className="CategoryAddForm">
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <CustomTextField
                label={Language.translate("Name")}
                value={categoryItemData.title}
                name="title"
                onChange={handleCategoryName}
                error={error.message}
                helperText={error.message}
              />
            </Grid>
            <Grid item xs={6}>
              <label htmlFor="contained-button-file">
                <CustomButton
                  size="small"
                  color="command"
                  id="btnUpload"
                  component="span"
                  variant="contained"
                  endIcon={
                    filesToUpload.length > 0 ? (
                      <span>
                        <Chip label={filesToUpload.length} size="small" />
                      </span>
                    ) : null
                  }
                  startIcon={<CustomIcon id="icon-upload" fontSize="small" />}
                >
                  {Language.translate("Upload Images").toUpperCase()}
                </CustomButton>
              </label>
              <input
                accept="image/*"
                multiple
                id="contained-button-file"
                type="file"
                onChange={(e) => handleFileRead(e)}
                className="fileUploadInput"
              />
            </Grid>
            <Grid item xs={6}>
              {uploadFileDisplay.length > 0 && (
                <Button startIcon={<DeleteIcon />} onClick={removeAll}>
                  {Language.translate("REMOVE ALL")}
                </Button>
              )}
            </Grid>
            {imageNameTaken.length > 0 ? (
              <Grid className="errorInfo" container item xs={12}>
                <Grid item xs={8}>
                  {imageNameTaken.length}{" "}
                  {Language.translate(
                    "Errors. File name already exists in database."
                  )}
                </Grid>
                <Grid item xs={4}>
                  <Button
                    className="errorBtn"
                    startIcon={<DeleteIcon />}
                    onClick={removeErrors}
                  >
                    {Language.translate("REMOVE ERRORS")}
                  </Button>
                </Grid>
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <ul className="listing imageList">
                {!_.isEmpty(uploadFileDisplay) ? (
                  <React.Fragment>
                    <ListItem className="header" key="header">
                      <ListItemText
                        primary={
                          <Grid container>
                            <Grid item xs={1}>
                              {Language.translate("Icon")}
                            </Grid>
                            <Grid item xs={9}>
                              {Language.translate("Name")}
                            </Grid>
                            <Grid item xs={2}>
                              {Language.translate("Action")}
                            </Grid>
                          </Grid>
                        }
                      />
                    </ListItem>
                    {uploadFileDisplay.map((file, index) => {
                      const errorState = _.includes(imageNameTaken, file.name);
                      return (
                        <ListItem
                          key={index}
                          className={`listItem ${errorState ? "error" : null}`}
                        >
                          <ListItemText
                            id={file.id}
                            primary={
                              <Grid container>
                                <Grid className="logoPreview" item xs={1}>
                                  <img
                                    className="picFile"
                                    thumbnail="picFile"
                                    alt=""
                                    src={URL.createObjectURL(file)}
                                  />
                                </Grid>
                                <Grid item xs={9}>
                                  {file.name}
                                </Grid>
                                <Grid item xs={2}>
                                  {errorState ? (
                                    <Button startIcon={<ErrorIcon />}>
                                      {Language.translate("ERROR")}
                                    </Button>
                                  ) : (
                                    <CustomButton
                                      color="action"
                                      onClick={() => removeError(file)}
                                      startIcon={<DeleteIcon />}
                                    >
                                      {Language.translate("REMOVE")}
                                    </CustomButton>
                                  )}
                                </Grid>
                              </Grid>
                            }
                          />
                        </ListItem>
                      );
                    })}
                  </React.Fragment>
                ) : null}
              </ul>
            </Grid>
            {categoryItemData.id && (
              <React.Fragment>
                {filesToDelete.length > 0 ? (
                  <Grid className="errorInfo" container item xs={12}>
                    <Grid item xs={10}>
                      {filesToDelete.length} {Language.translate("Selected")}
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        className="errorBtn"
                        startIcon={<DeleteIcon />}
                        onClick={() =>
                          deleteCategoryImages(categoryItemData.id)
                        }
                      >
                        {Language.translate("DELETE")}
                      </Button>
                    </Grid>
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  <h4
                    style={{
                      fontWeight: "normal",
                      fontSize: "24px",
                      marginTop: "0px",
                      marginBottom: "10px",
                    }}
                  >
                    {Language.translate("Images")} ({imagesCollection?.length})
                  </h4>
                  <button
                    style={{ display: "none" }}
                    onClick={setImagesState}
                    id="hiddenBtn"
                  >
                    Hidden Button
                  </button>
                  {imagesCollection?.map((imgId) => {
                    const className =
                      isPoiImageUsed(imgId) ||
                      !Permission.verify("poi", "delete")
                        ? "imgDisabled"
                        : "imgUnChecked";
                    return (
                      <React.Fragment key={imgId}>
                        <input
                          type="checkbox"
                          id={`img${imgId}`}
                          name="images"
                          value={imgId}
                          style={{ display: "none" }}
                        />
                        <label htmlFor={`img${imgId}`}>
                          <img
                            onClick={() => mark(`Img${imgId}`)}
                            id={`Img${imgId}`}
                            src={Images.getImageSource(imgId)}
                            width="40"
                            alt=""
                            className={className}
                          />
                        </label>
                      </React.Fragment>
                    );
                  })}
                </Grid>
              </React.Fragment>
            )}
            <Grid justifyContent="flex-start" className="poiFooter" container>
              <Grid item xs={6}>
                <CustomButton
                  onClick={saveAndBackToList}
                  size="medium"
                  color="action"
                  id="btnAdd"
                  variant="contained"
                  type="submit"
                  disabled={disableButton}
                  startIcon={<CheckIcon />}
                >
                  {Language.translate("Save")}
                </CustomButton>
              </Grid>
              <Grid item align="right" xs={6}>
                <CustomButton
                  onClick={
                    disableButton
                      ? cancelAndBackToListingMode
                      : handleDialogOpen
                  }
                  size="medium"
                  color="cancel"
                  id="btnCancel"
                  variant="contained"
                  startIcon={<CloseIcon />}
                >
                  {Language.translate("Cancel")}
                </CustomButton>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
}

export default POICategorySettings;
