import React, { useEffect, useState, useContext } from "react";

// Material-UI
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";

// Components
import CustomButton from "@Components/CustomButton";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

// Internal
import Alert from "sccAlert";
import AlertMenu from "sccAlertMenu";
import Chat from "sccChat";
import Device from "sccDevice";
import DeviceMenu from "sccDeviceMenu";
import EnvVar from "sccEnvVar";
import Geofence from "sccGeofence";
import GeofenceOverlay from "sccGeofenceOverlay";
import Group from "sccGroup";
import History from "../../history/components/";
import Images from "sccImage";
import Language from "sccLanguage";
import Load from "sccLoad";
import Maps from "sccMaps";
import OlHistoryMap from "sccOlMapHistory";
import OlHistoryOverlay from "sccHistoryOverlay";
import OlHistoryTrailOverlay from "sccHistoryTrailOverlay";
import OlMapHistory from "../../history/scripts/OlMapHistory";
import Options from "sccOptions";
import Permission from "sccPermission";
import PermissionMenu from "sccPermissionMenu";
import Poi from "sccPoi";
import PoiCategory from "sccPoiCategory";
import PoiMenu from "sccPoiMenu";
import PoiOverlay from "sccPoiOverlay";
import Profile from "sccProfile";
import Socket from "sccSocket";
import UserSetting from "sccUserSetting";

//Module load based on the sequence followed in the angular based system
// permission, env_var, language, image, poi/category, user_setting, profile, profile_menu
// data_display, message, map, clock, alert, alert_menu, device, device_menu, group, cargo
// device_overlay, socket, poi, poi_overlay, poi_menu, geofence, geofence_overlay, geofence_menu
// nr, nr_menu, sa, sa_menu, client, ar, ar_menu, message, message_menu, user_setting_menu
// user, user_menu, permission_menu, options

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "fixed",
    zIndex: 50000,
    top: 20,
    left: "0",
    width: "102%",
    height: "100%",
    backgroundColor: theme.palette.colors.blue.dark,
    padding: theme.spacing(1),
    color: theme.palette.colors.white.main,
  },

  logo: {
    width: "200px",
  },
}));

function HistoryMain() {
  window.Promise = require("bluebird");

  const [resolvedModuleCount, setResolvedModuleCount] = useState(0);
  const [totalModuleCount, setTotalModuleCount] = useState(0);
  const [initialized, setInitialized] = useState(Load.initialized);
  const [allModulesLoaded, setAllModulesLoaded] = useState(
    Load.allModulesLoaded
  );
  const [openModel, setOpenModel] = useState(true);
  const [refreshText] = useState(Language.translate("Refresh"));
  const [mwState] = useContext(ModuleWrapperContext);
  const classes = useStyles();

  let $scope = mwState.$scope;
  var sequencedInit = [
    {
      module: Permission,
      dependencies: [],
    },
    {
      module: EnvVar,
      dependencies: [],
    },
    {
      module: Language,
      dependencies: [],
    },
    {
      module: Images,
      dependencies: [],
    },
    {
      module: UserSetting,
      dependencies: [],
    },
    {
      module: Options,
      dependencies: [],
    },
    {
      module: Profile,
      dependencies: [],
    },
    {
      module: { moduleName: "permission_menu" },
      dependencies: ["permission"],
      initFunc: function () {
        return PermissionMenu.init($scope);
      },
    },
    {
      module: Device,
      dependencies: ["permission"],
    },
    {
      module: { moduleName: "device_menu" },
      dependencies: ["device"],
      initFunc: function () {
        return DeviceMenu.init($scope);
      },
    },
    {
      module: Poi,
      dependencies: ["permission"],
    },
    {
      module: { moduleName: "poi_menu" },
      dependencies: ["poi"],
      initFunc: function () {
        return PoiMenu.init($scope);
      },
    },
    {
      module: Chat,
      dependencies: ["permission"],
    },
    {
      module: Alert,
      dependencies: ["permission"],
    },
    {
      module: { moduleName: "alert_menu" },
      dependencies: ["alert"],
      initFunc: function () {
        return AlertMenu.init($scope);
      },
    },
    {
      module: History,
      dependencies: ["permission"],
    },
    {
      module: Maps,
      dependencies: ["permission"],
    },
    {
      module: Geofence,
      dependencies: ["permission", "device"],
    },
    {
      module: OlHistoryMap,
      dependencies: ["user_setting", "env_var"],
      initFunc: function () {
        return OlHistoryMap.init($scope);
      },
    },
    {
      module: Group,
      dependencies: ["device", "env_var"],
    },
    {
      module: Socket,
      dependencies: ["device"],
    },
    {
      module: GeofenceOverlay,
      dependencies: ["map", "geofence"],
      initFunc: function () {
        return GeofenceOverlay.init({
          OlMap: OlMapHistory,
          hideLayerOnStart: true,
        });
      },
    },
    {
      module: PoiCategory,
      dependencies: ["permission"],
    },
    {
      module: PoiOverlay,
      dependencies: ["image", "map"],
      initFunc: function () {
        return PoiOverlay.init({
          OlMap: OlMapHistory,
          hideLayerOnStart: true,
        });
      },
    },
    {
      module: OlHistoryOverlay,
      dependencies: ["map"],
    },
    {
      module: OlHistoryTrailOverlay,
      dependencies: ["map"],
    },
  ];
  const loader = (dependencies, module, initFunc) => {
    return new Promise((resolve) => {
      Load.inject(dependencies, module, initFunc);
      resolve(module);
    });
  };

  const startTime = Date.now();
  const doNextPromise = (index) => {
    loader(
      sequencedInit[index].dependencies,
      sequencedInit[index].module,
      sequencedInit[index].initFunc
    ).then((module) => {
      index++;
      if (index < sequencedInit.length) {
        doNextPromise(index);
      } else
        console.log(`@@@Total: ${(Date.now() - startTime) / 1000} seconds.`);
    });
  };

  const updateLoaderStats = () => {
    const updateInterval = setInterval(() => {
      setTotalModuleCount((p) => Load.totalModuleCount);
      setResolvedModuleCount((p) => Load.resolvedModuleCount);
      if (
        Load.totalModuleCount === Load.resolvedModuleCount &&
        Load.totalModuleCount > 10
      ) {
        setAllModulesLoaded(true);
        if (
          window.googleMapsAPIloaded ||
          window.googleMapsAPIresponseWaitExpired
        ) {
          setInitialized(true);
          clearInterval(updateInterval);
        }
      }
    }, 100);
  };

  useEffect(() => {
    doNextPromise(0);
    Load.resolve();
    updateLoaderStats();
  }, []);

  const handleClose = () => {
    setOpenModel(false);
  };

  return (
    <>
      <div id="mapContextMenu"></div>
      {!initialized && (
        <Modal open={openModel} onClose={handleClose}>
          <Grid
            container
            spacing={5}
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            className={classes.paper}
          >
            <Grid item>
              <CircularProgress color="inherit" size="15rem" thickness={5} />
            </Grid>
            <Grid item>
              <img
                className={classes.logo}
                src={
                  Images.getImageCollection("platform_images").scc_titan_logo
                    .default
                }
                alt="TITAN BY NORTAC"
              />
            </Grid>
            {!allModulesLoaded ? (
              <Grid item>
                <p>
                  {Language.translate("Loading")}&nbsp;
                  {resolvedModuleCount}&nbsp;
                  {Language.translate("of")}&nbsp;
                  {totalModuleCount}&nbsp;{Language.translate("Modules")}
                </p>
              </Grid>
            ) : null}
            {allModulesLoaded && !initialized ? (
              <Grid item>
                <p>
                  {Language.translate("Initializing")}&nbsp;
                  {Language.translate("Maps")}
                </p>
              </Grid>
            ) : null}
            <Grid container item justifyContent="flex-end">
              <CustomButton
                size="large"
                color="cancel"
                id="btnRefresh"
                variant="contained"
                type="submit"
              >
                {refreshText}
              </CustomButton>
            </Grid>
          </Grid>
        </Modal>
      )}
      <History initialized={initialized} />
    </>
  );
}

export default HistoryMain;
